/// REDUX ///
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { Fragment } from "react";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { faShop } from "@fortawesome/free-solid-svg-icons";
/// COMPONENTS ///
import DashboardTile from "components/universal/tiles/dashboard";
/// STYLES ///
import { title_style } from "styles/quote_preview/section";
import { tile_ico_style } from "styles/dashboard";
import { header_style } from "styles/universal/section_header";
/// URLS ///
import { event_url } from "components/navigation/endpoints";
import { login_url } from "components/navigation/endpoints";
import { org_url } from "components/navigation/endpoints";
import { shops_url } from "components/navigation/endpoints";
import { wo_url } from "components/navigation/endpoints";

export default function MarketDashboard() {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.authentication);

  if (!user.id) {
    navigate(login_url, { replace: false });
  }

  return (
    <Fragment>
      <Grid container item xs={12} md={6}>
        <Grid item xs={12} sx={{ marginTop: "20px" }}>
          <Typography variant="h6" sx={title_style}>
            Manage Market
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={header_style}></Box>
        </Grid>
        <DashboardTile
          title="Work Orders"
          index={0}
          url={wo_url}
          icon={<FontAwesomeIcon icon={faClipboardCheck} style={tile_ico_style} />} />
        <DashboardTile
          title="Calendar"
          index={1}
          url={`${event_url}/month`}
          icon={<FontAwesomeIcon icon={faCalendar} style={tile_ico_style} />} />
        <Grid container item xs={12}>
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Typography variant="h6" sx={title_style}>
              Manage Organization
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={12}>
            <DashboardTile
              title="Organization"
              index={4}
              url={org_url}
              icon={<FontAwesomeIcon icon={faBuilding} style={tile_ico_style} />} />
            <DashboardTile
              title="Shops"
              index={5}
              url={shops_url}
              icon={<FontAwesomeIcon icon={faShop} style={tile_ico_style} />} />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Typography variant="h6" sx={title_style}>
              Learn
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <DashboardTile
            title="Latest Updates"
            external={true}
            index={4}
            url="https://learn.arrisstone.com"
            icon={<FontAwesomeIcon icon={faDatabase} style={tile_ico_style} />} />
          <DashboardTile
            title="Training Videos"
            external={true}
            index={5}
            url="https://learn.arrisstone.com/training"
            icon={<FontAwesomeIcon icon={faGraduationCap} style={tile_ico_style} />} />
        </Grid>
      </Grid>
    </Fragment>
  )
}
