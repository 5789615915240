/// REACT ///
import { Fragment } from "react";
/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopFinishedEdge } from "api/actions/price_list";
/// TYPES ///
import { IFinishedEdge } from "api/types/price_list/edge/finished";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  finished_edge: IFinishedEdge
}

export default function FinishedEdge({ index, last, finished_edge }: Props) {
  const dispatch = useAppDispatch();

  return (
    <Fragment>
      <PriceRow
        index={index}
        last={false}
        original_price={finished_edge?.eased_price ?? 0}
        title={`${getMaterialHeightDisplay(finished_edge?.material_thickness ?? "")} Eased Edge`}
        end_text="$/LIN"
        update={price => dispatch(updateShopFinishedEdge({ eased_price: price }, finished_edge.id))} />
      <PriceRow
        index={index}
        last={false}
        original_price={finished_edge?.bevel_price ?? 0}
        title={`${getMaterialHeightDisplay(finished_edge?.material_thickness ?? "")} Bevel`}
        end_text="$/LIN"
        update={price => dispatch(updateShopFinishedEdge({ bevel_price: price }, finished_edge.id))} />
      <PriceRow
        index={index}
        last={false}
        original_price={finished_edge?.bullnose_price ?? 0}
        title={`${getMaterialHeightDisplay(finished_edge?.material_thickness ?? "")} Bullnose`}
        end_text="$/LIN"
        update={price => dispatch(updateShopFinishedEdge({ bullnose_price: price }, finished_edge.id))} />
      <PriceRow
        index={index}
        last={last}
        original_price={finished_edge?.specialty_price ?? 0}
        title={`${getMaterialHeightDisplay(finished_edge?.material_thickness ?? "")} Specialty Edges`}
        end_text="$/LIN"
        update={price => dispatch(updateShopFinishedEdge({ specialty_price: price }, finished_edge.id))} />
    </Fragment>
  )
}
