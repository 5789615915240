import { INotchedCorner } from "api/types/price_list/corner/notched";

export enum ActionType {
  GET_NOTCHED_CORNER_PRICES = "GET_NOTCHED_CORNER_PRICES",
  UPDATE_NOTCHED_CORNER_PRICE = "UPDATE_NOTCHED_CORNER_PRICE"
}

interface IGetNotchedCorners {
  type: ActionType.GET_NOTCHED_CORNER_PRICES
  notched_corners: INotchedCorner[]
}

interface IUpdateNotchedCorner {
  type: ActionType.UPDATE_NOTCHED_CORNER_PRICE,
  notched_corner: INotchedCorner
}

export type Action = IGetNotchedCorners | IUpdateNotchedCorner;
