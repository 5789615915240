import { IWaterfall } from "api/types/price_list/edge/waterfall";

export enum ActionType {
  GET_WATERFALL_PRICES = "GET_WATERFALL_PRICES",
  UPDATE_WATERFALL_PRICE = "UPDATE_WATERFALL_PRICE",
}

interface IGetWaterfallPrices {
  type: ActionType.GET_WATERFALL_PRICES
  waterfalls: IWaterfall[]
}

interface IUpdateWaterfallPrice {
  type: ActionType.UPDATE_WATERFALL_PRICE,
  waterfall: IWaterfall
}

export type Action =
  IGetWaterfallPrices |
  IUpdateWaterfallPrice;
