import { IFabricationCharge } from "api/types/price_list/fabrication_charge";

export enum ActionType {
  GET_FABRICATION_CHARGE_PRICES = "GET_FABRICATION_CHARGE_PRICES",
  UPDATE_FABRICATION_CHARGE_PRICE = "UPDATE_FABRICATION_CHARGE_PRICE"
}

interface IGetFabricationChargePrices {
  type: ActionType.GET_FABRICATION_CHARGE_PRICES
  fabrication_charges: IFabricationCharge[]
}

interface IUpdateFabricationChargePrice {
  type: ActionType.UPDATE_FABRICATION_CHARGE_PRICE,
  fabrication_charge: IFabricationCharge
}

export type Action =
  IGetFabricationChargePrices |
  IUpdateFabricationChargePrice;
