/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopInsideDiagonalCorner } from "api/actions/price_list";
/// TYPES ///
import { IInsideDiagonalCorner } from "api/types/price_list/corner/inside_diagonal";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  inside_diagonal_corner: IInsideDiagonalCorner
}

export default function InsideDiagonalCorner({ index, last, inside_diagonal_corner }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={inside_diagonal_corner?.price ?? 0}
      title={getMaterialHeightDisplay(inside_diagonal_corner?.material_thickness ?? "")}
      end_text="$"
      end_padding="45px"
      update={price => dispatch(updateShopInsideDiagonalCorner({ price: price }, inside_diagonal_corner.id))} />
  )
}
