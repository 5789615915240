import { IInsideDiagonalCorner } from "api/types/price_list/corner/inside_diagonal";

export enum ActionType {
  GET_INSIDE_DIAGONAL_CORNER_PRICES = "GET_INSIDE_DIAGONAL_CORNER_PRICES",
  UPDATE_INSIDE_DIAGONAL_CORNER_PRICE = "UPDATE_INSIDE_DIAGONAL_CORNER_PRICE"
}

interface IGetInsideDiagonalCorners {
  type: ActionType.GET_INSIDE_DIAGONAL_CORNER_PRICES
  inside_diagonal_corners: IInsideDiagonalCorner[]
}

interface IUpdateInsideDiagonalCorner {
  type: ActionType.UPDATE_INSIDE_DIAGONAL_CORNER_PRICE,
  inside_diagonal_corner: IInsideDiagonalCorner
}

export type Action = IGetInsideDiagonalCorners | IUpdateInsideDiagonalCorner;
