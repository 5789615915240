/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/price_lists";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/price_lists";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// TYPES ///
import { IInstallationCharge } from "api/types/price_list/installation_charge";
/// URLS ///
import { url_prefix } from "settings";
import { shop_installation_charge_url } from "../endpoint";

export const updateShopInstallationCharge = (installation_charge_update: IInstallationCharge, id: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.PRICE_LIST_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${shop_installation_charge_url}/${id}/?`;

    try {
      const { data: installation_charge } = await axios.patch(url, installation_charge_update, config);

      dispatch({
        type: ActionType.UPDATE_INSTALLATION_CHARGE_PRICE,
        installation_charge
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.PRICE_LIST_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
