/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopBumpInArc } from "api/actions/price_list";
/// TYPES ///
import { IBumpInArc } from "api/types/price_list/bump/in_arc";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  bump_in_arc: IBumpInArc
}

export default function BumpInArc({ index, last, bump_in_arc }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={bump_in_arc?.price ?? 0}
      title={getMaterialHeightDisplay(bump_in_arc?.material_thickness ?? "")}
      end_text="$"
      end_padding="45px"
      update={price => dispatch(updateShopBumpInArc({ price: price }, bump_in_arc.id))} />
  )
}
