/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopSinkCutout } from "api/actions/price_list";
/// TYPES ///
import { ISinkCutout } from "api/types/price_list/cutout/sink";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getSinkTypes } from "values/values";
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  sink_cutout: ISinkCutout
}

export default function SinkCutout({ index, last, sink_cutout }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={sink_cutout?.price ?? 0}
      title={`${getMaterialHeightDisplay(sink_cutout?.material_thickness ?? "")} ${getSinkTypes(sink_cutout?.sink_type ?? "")}`}
      end_text="$"
      end_padding="45px"
      update={price => dispatch(updateShopSinkCutout({ price: price }, sink_cutout.id))} />
  )
}
