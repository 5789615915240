/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/price_lists";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/price_lists";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// TYPES ///
import { IInsideRadiusCorner } from "api/types/price_list/corner/inside_radius";
/// URLS ///
import { url_prefix } from "settings";
import { shop_inside_radius_corner_url } from "../../endpoint";

export const updateShopInsideRadiusCorner = (inside_radius_corner_update: IInsideRadiusCorner, id: number | string) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {

    const { user } = getState().authentication;

    dispatch({
      type: ActionType.PRICE_LIST_UPDATING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${shop_inside_radius_corner_url}/${id}/?`;

    try {
      const { data: inside_radius_corner } = await axios.patch(url, inside_radius_corner_update, config);

      dispatch({
        type: ActionType.UPDATE_INSIDE_RADIUS_CORNER_PRICE,
        inside_radius_corner
      });
    }
    catch (err) {
      if (err instanceof AxiosError) {
        dispatch({
          type: ActionType.PRICE_LIST_ERROR,
          payload: err.message
        });
        if (err && err.response && err.response.status === 401) {
          dispatch({
            type: AuthActionType.AUTH_FAILED
          });
        }
      }
    }
  }
}
