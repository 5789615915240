/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getShopColors } from "api/actions/shop_material";
import { getShopMaterial } from "api/actions/shop_material";
import { deleteShopMaterial } from "api/actions/shop_material";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import CallbackDialog from "components/universal/callback_dialog";
import Loading from "components/universal/loading";
import PageTitle from "components/universal/page/title";
import MaterialTable from "./table";
/// FM ///
import { motion } from "framer-motion";
/// ICON ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { row_header_style } from "styles/universal/row";
import { title_style } from "styles/universal/page";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";
import { getMaterialTypeDisplay } from "values/material";
/// BREADCRUMBS ///
import { material_list } from "values/breadcrumbs/materials/shop";
import { material_type } from "values/breadcrumbs/materials/shop";
import { c_material } from "values/breadcrumbs/materials/shop";
/// URLS ///
import { create_url } from "components/navigation/endpoints";
import { material_url } from "components/navigation/endpoints";
import { shop_url } from "components/navigation/endpoints";

export default function ShopMaterial() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, type } = useParams();
  const { colors } = useAppSelector(state => state.shop_material);
  const { material } = useAppSelector(state => state.shop_material);
  const { loading } = useAppSelector(state => state.shop_material);
  const [open_delete, setOpenDelete] = useState<boolean>(false);

  useEffect(() => {
    if (!material.id || material.id.toString() !== id) {
      dispatch(getShopMaterial(id));
    }
  }, [dispatch, id, material]);

  useEffect(() => {
    dispatch(getShopColors(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (material?.name) {
      dispatch(setBreadcrumbs([
        material_list,
        { ...material_type, label: getMaterialTypeDisplay(type), url: material_type.url.replace("material_type", type) },
        {
          ...c_material,
          label: `${material?.name} ${getMaterialHeightDisplay((material?.thickness ?? ""))}`,
          url: c_material.url.replace("material_type", type).replace("id", id)
        }
      ]));
    }
  }, [type, dispatch, id, material]);

  const deleteMaterial = () => {
    dispatch(deleteShopMaterial(material.id));
    navigate(-1);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <Grid container item xs={12} lg={6}>
          <PageTitle title={`${material?.name} ${getMaterialHeightDisplay((material?.thickness ?? ""))}`} endProps={
            <Box display="flex" justifyContent="flex-end">
              {
                !material?.required ?
                  <BaseButton icon={faTrash} clickEvent={() => setOpenDelete(true)} error text="Delete Material" /> :
                  <Typography variant="h6" color="primary">Standard Material</Typography>
              }
            </Box>
          } />
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={6} sx={{ ...row_header_style, paddingTop: "7px", paddingLeft: "2px" }}>
            <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <Typography variant="h6" sx={title_style}>
                {colors?.length} results
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ ...row_header_style, paddingTop: "7px", paddingLeft: "2px" }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" sx={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <BaseButton icon={faPlus} clickEvent={() => navigate(`/${shop_url}/${material_url}/${type}/${id}/${create_url}`)} text="Add Colors" />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable />
        </Grid>
      </Grid>
      <CallbackDialog
        title="Delete Material"
        open={open_delete}
        close={() => setOpenDelete(false)}
        callback={deleteMaterial}
        confirm_text="Delete"
        body={`Are you sure you want to delete ${material.name} ${material.thickness}? All colors for this material will be permanently deleted.`} />
      <Loading loading={loading} />
    </motion.div>
  )
}
