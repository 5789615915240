import { IOutletCutout } from "api/types/price_list/cutout/outlet";

export enum ActionType {
  GET_OUTLET_CUTOUT_PRICES = "GET_OUTLET_CUTOUT_PRICES",
  UPDATE_OUTLET_CUTOUT_PRICE = "UPDATE_OUTLET_CUTOUT_PRICE"
}

interface IGetOutletCutouts {
  type: ActionType.GET_OUTLET_CUTOUT_PRICES
  outlet_cutouts: IOutletCutout[]
}

interface IUpdateOutletCutout {
  type: ActionType.UPDATE_OUTLET_CUTOUT_PRICE,
  outlet_cutout: IOutletCutout
}

export type Action =
  IGetOutletCutouts |
  IUpdateOutletCutout;
