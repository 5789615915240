import { IBumpOut } from "api/types/price_list/bump/out";

export enum ActionType {
  GET_BUMP_OUT_PRICES = "GET_BUMP_OUT_PRICES",
  UPDATE_BUMP_OUT_PRICE = "UPDATE_BUMP_OUT_PRICE"
}

interface IGetBumpOuts {
  type: ActionType.GET_BUMP_OUT_PRICES
  bump_outs: IBumpOut[]
}

interface IUpdateBumpOut {
  type: ActionType.UPDATE_BUMP_OUT_PRICE,
  bump_out: IBumpOut
}

export type Action = IGetBumpOuts | IUpdateBumpOut;
