/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopFabricationCharge } from "api/actions/price_list";
/// TYPES ///
import { IFabricationCharge } from "api/types/price_list/fabrication_charge";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  fabrication_charge: IFabricationCharge
}

export default function FabricationCharge({ index, last, fabrication_charge }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={fabrication_charge?.price ?? 0}
      title={getMaterialHeightDisplay(fabrication_charge?.material_thickness ?? "")}
      end_text="$/SQFT"
      update={price => dispatch(updateShopFabricationCharge({ price: price }, fabrication_charge.id))} />
  )
}
