/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// TYPES ///
import { IPriceControl } from "api/types/price_list/price_control";
/// ACTIONS ///
import { getPriceList } from "api/actions/price_list";
import { getPriceControls } from "api/actions/price_list";
import { updatePriceControls } from "api/actions/price_list";
import { getShopBumpIns } from "api/actions/price_list";
import { getShopBumpInArcs } from "api/actions/price_list";
import { getShopBumpOuts } from "api/actions/price_list";
import { getShopBumpOutArcs } from "api/actions/price_list";
import { getShopFullArcs } from "api/actions/price_list";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BumpIn from "./bump_in";
import BumpInArc from "./bump_in_arc";
import BumpOut from "./bump_out";
import BumpOutArc from "./bump_out_arc";
import FullArc from "./full_arc";
import Loading from "components/universal/loading";
import PageTitle from "components/universal/page/title";
import PriceControlSwitch from "components/price_lists/price_control/switch";
/// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { title_style } from "styles/quote_preview/section";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { list } from "values/breadcrumbs/market/pl";
import { overview } from "values/breadcrumbs/market/pl";

export default function CutoutPriceList() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { price_list } = useAppSelector(state => state.price_list);
  const { bump_ins } = useAppSelector(state => state.price_list);
  const { bump_in_arcs } = useAppSelector(state => state.price_list);
  const { bump_outs } = useAppSelector(state => state.price_list);
  const { bump_out_arcs } = useAppSelector(state => state.price_list);
  const { full_arcs } = useAppSelector(state => state.price_list);
  const { price_controls } = useAppSelector(state => state.price_list);
  const { updating } = useAppSelector(state => state.price_list);
  const [update_price_control, setUpdatePriceControl] = useState<IPriceControl>({});

  useEffect(() => {
    setUpdatePriceControl(price_controls);
  }, [price_controls?.id]);

  useEffect(() => {
    if (!(price_list?.id) || price_list.id.toString() !== id) {
      dispatch(getPriceList(id));
    }
  }, [dispatch, id, price_list?.id]);

  useEffect(() => {
    if (id) {
      dispatch(getPriceControls());
      dispatch(getShopBumpIns(id));
      dispatch(getShopBumpInArcs(id));
      dispatch(getShopBumpOuts(id));
      dispatch(getShopBumpOutArcs(id));
      dispatch(getShopFullArcs(id));
    }
    dispatch(setBreadcrumbs([list, { ...overview, url: overview.url.replace("id", id) }]));
  }, [id, dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <PageTitle title={`${price_list?.name} Bumps` ?? ""} endProps={<div></div>} />
        <Grid container item direction="row" xs={12} justifyContent="space-between">
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Bump Ins
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_bump_in}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_bump_in: checked });
                  dispatch(updatePriceControls({ use_bump_in: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_bump_in ?
              bump_ins.map((bump_in, index) => <BumpIn key={index} index={index} bump_in={bump_in} last={index === bump_ins.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Bump In Arcs
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_bump_in_arc}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_bump_in_arc: checked });
                  dispatch(updatePriceControls({ use_bump_in_arc: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_bump_in_arc ?
              bump_in_arcs.map((bump_in_arc, index) => <BumpInArc key={index} index={index} bump_in_arc={bump_in_arc} last={index === bump_in_arcs.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Bump Outs
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_bump_out}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_bump_out: checked });
                  dispatch(updatePriceControls({ use_bump_out: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_bump_out ?
              bump_outs.map((bump_out, index) => <BumpOut key={index} index={index} bump_out={bump_out} last={index === bump_outs.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Bump Out Arcs
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_bump_out_arc}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_bump_out_arc: checked });
                  dispatch(updatePriceControls({ use_bump_out_arc: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {update_price_control?.use_bump_out_arc ?
            bump_out_arcs.map((bump_out_arc, index) => <BumpOutArc key={index} index={index} bump_out_arc={bump_out_arc} last={index === bump_out_arcs.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Full Arcs
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_full_arc}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_full_arc: checked });
                  dispatch(updatePriceControls({ use_full_arc: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {update_price_control?.use_full_arc ?
            full_arcs.map((full_arc, index) => <FullArc key={index} index={index} full_arc={full_arc} last={index === full_arcs.length - 1} />) : null
          }
        </Grid>
      </Grid>
      <Loading loading={updating} />
    </motion.div>
  )
}
