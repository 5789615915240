import { IMiter } from "api/types/price_list/edge/miter";

export enum ActionType {
  GET_MITER_PRICES = "GET_MITER_PRICES",
  UPDATE_MITER_PRICE = "UPDATE_MITER_PRICE",
}

interface IGetMiterPrices {
  type: ActionType.GET_MITER_PRICES
  miters: IMiter[]
}

interface IUpdateMiterPrice {
  type: ActionType.UPDATE_MITER_PRICE,
  miter: IMiter
}

export type Action =
  IGetMiterPrices |
  IUpdateMiterPrice;
