import { IPriceControl } from "api/types/price_list/price_control";

export enum ActionType {
  GET_PRICE_CONTROLS = "GET_PRICE_CONTROLS",
  UPDATE_PRICE_CONTROLS = "UPDATE_PRICE_CONTROLS"
}

interface IGetIPriceControls {
  type: ActionType.GET_PRICE_CONTROLS
  price_controls: IPriceControl
}

interface IUpdateIPriceControls {
  type: ActionType.UPDATE_PRICE_CONTROLS,
  price_controls: IPriceControl
}

export type Action =
  IGetIPriceControls |
  IUpdateIPriceControls;
