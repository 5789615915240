import { IApplianceEdge } from "api/types/price_list/edge/appliance";

export enum ActionType {
  GET_APPLIANCE_EDGE_PRICES = "GET_APPLIANCE_EDGE_PRICES",
  UPDATE_APPLIANCE_EDGE_PRICE = "UPDATE_APPLIANCE_EDGE_PRICE"
}

interface IGetApplianceEdgePrices {
  type: ActionType.GET_APPLIANCE_EDGE_PRICES
  appliance_edges: IApplianceEdge[]
}

interface IUpdateApplianceEdgePrice {
  type: ActionType.UPDATE_APPLIANCE_EDGE_PRICE,
  appliance_edge: IApplianceEdge
}

export type Action =
  IGetApplianceEdgePrices |
  IUpdateApplianceEdgePrice;
