import { grey_color } from "styles/style";

export const motion_div_style = {
  paddingLeft: "5px",
  paddingRight: "5px"
}

export const stack_style = (last: boolean) => ({
  padding: 1,
  borderTop: `1px solid ${grey_color}`,
  borderLeft: `1px solid ${grey_color}`,
  borderRight: `1px solid ${grey_color}`,
  borderBottom: last ? `1px solid ${grey_color}` : "none",
  width: "50%"
});

export const textfield_style = {
  fontSize: "1.4rem"
}

export const adorn_type_style = {
  paddingBottom: "13px",
  color: grey_color
}
