import { IInsideRadiusCorner } from "api/types/price_list/corner/inside_radius";

export enum ActionType {
  GET_INSIDE_RADIUS_CORNER_PRICES = "GET_INSIDE_RADIUS_CORNER_PRICES",
  UPDATE_INSIDE_RADIUS_CORNER_PRICE = "UPDATE_INSIDE_RADIUS_CORNER_PRICE",
}

interface IGetInsideRadiusCorners {
  type: ActionType.GET_INSIDE_RADIUS_CORNER_PRICES
  inside_radius_corners: IInsideRadiusCorner[]
}

interface IUpdateInsideRadiusCorner {
  type: ActionType.UPDATE_INSIDE_RADIUS_CORNER_PRICE,
  inside_radius_corner: IInsideRadiusCorner
}

export type Action = IGetInsideRadiusCorners | IUpdateInsideRadiusCorner;
