import { PriceList } from "api/types/price_list/price_list";

export enum ActionType {
  GET_PRICE_LISTS = "GET_PRICE_LISTS",
  GET_PRICE_LIST = "GET_PRICE_LIST",
  UPDATE_PRICE_LIST = "UPDATE_PRICE_LIST"
}

interface IGetPriceLists {
  type: ActionType.GET_PRICE_LISTS
  price_lists: PriceList[]
}

interface IGetPriceList {
  type: ActionType.GET_PRICE_LIST,
  price_list: PriceList
}

interface IUpdatePriceList {
  type: ActionType.UPDATE_PRICE_LIST,
  price_list: PriceList
}

export type Action = IGetPriceLists | IGetPriceList | IUpdatePriceList;
