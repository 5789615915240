import { Vector2 } from "api/types/sketch";
import { CutBump } from "api/types/sketch";
import { BumpConstruct } from "api/types/sketch";
import { BumpArcConstruct } from "api/types/sketch";
import { constructBumpIn } from "../construct";
import { constructBumpInArc } from "../construct";
import { constructBumpOutArc } from "../construct";
import { constructBumpOut } from "../construct";
import { getDistance } from "functions/sketch/math";
import { getDistantPoint } from "functions/sketch/math";
import { FULL_ARC } from "values/values";
import { NO_BUMP } from "values/values";
import { BUMP_IN } from "values/values";
import { BUMP_IN_ARC } from "values/values";
import { BUMP_OUT } from "values/values";
import { BUMP_OUT_ARC } from "values/values";
import { inch_to_pixel } from "values/values";

export function getBumpPoints(
  points: number[][],
  unsorted_bumps: CutBump[],
  current_location: Vector2,
  next_location: Vector2,
  angle: number,
  zoom: number
) {
  let is_full_arc: boolean = false;
  const bumps: CutBump[] = [...unsorted_bumps];
  bumps.sort(function (a, b) {
    if (a.distance < b.distance) {
      return -1;
    }
    else if (a.distance > b.distance) {
      return 1;
    }
    else {
      return 0;
    }
  });

  for (let j = 0; j < bumps.length; j++) {
    if (bumps[j].bump_type === FULL_ARC) {
      is_full_arc = true;
      const distance: number = getDistance(current_location, next_location);
      const midpoint: Vector2 = getDistantPoint(current_location, angle + 90, distance / 2);
      const apex: Vector2 = getDistantPoint(midpoint, angle, bumps[j].depth);
      points.push([apex.X, apex.Y]);
    }
  }

  if (!is_full_arc) {
    for (let j = 0; j < bumps.length; j++) {
      if (bumps[j].bump_type === NO_BUMP) {
        continue;
      }
      else if (bumps[j].bump_type === BUMP_IN) {
        const bump_construct: BumpConstruct = constructBumpIn(
          current_location,
          next_location,
          angle,
          bumps[j].distance * inch_to_pixel,
          bumps[j].width * inch_to_pixel,
          bumps[j].depth * inch_to_pixel,
          bumps[j].left_side_angle,
          bumps[j].right_side_angle,
          zoom
        );
        points.push([bump_construct.A.X, bump_construct.A.Y]);
        points.push([bump_construct.B.X, bump_construct.B.Y]);
        points.push([bump_construct.C.X, bump_construct.C.Y]);
        points.push([bump_construct.D.X, bump_construct.D.Y]);
      }
      else if (bumps[j].bump_type === BUMP_OUT) {
        const bump_construct: BumpConstruct = constructBumpOut(
          current_location,
          next_location,
          angle,
          bumps[j].distance * inch_to_pixel,
          bumps[j].width * inch_to_pixel,
          bumps[j].depth * inch_to_pixel,
          bumps[j].left_side_angle,
          bumps[j].right_side_angle,
          zoom
        );

        points.push([bump_construct.A.X, bump_construct.A.Y]);
        points.push([bump_construct.B.X, bump_construct.B.Y]);
        points.push([bump_construct.C.X, bump_construct.C.Y]);
        points.push([bump_construct.D.X, bump_construct.D.Y]);
      }
      else if (bumps[j].bump_type === BUMP_IN_ARC) {
        const bump_construct: BumpArcConstruct = constructBumpInArc(
          current_location,
          next_location,
          angle,
          bumps[j].distance * inch_to_pixel,
          bumps[j].width * inch_to_pixel,
          bumps[j].depth * inch_to_pixel / 2,
          zoom
        );
        points.push([bump_construct.start.X, bump_construct.start.Y]);
        points.push([bump_construct.top.X, bump_construct.top.Y]);
        points.push([bump_construct.end.X, bump_construct.end.Y]);
      }
      else if (bumps[j].bump_type === BUMP_OUT_ARC) {
        const bump_construct: BumpArcConstruct = constructBumpOutArc(
          current_location,
          next_location,
          angle,
          bumps[j].distance * inch_to_pixel,
          bumps[j].width * inch_to_pixel,
          bumps[j].depth * inch_to_pixel / 2,
          zoom
        );
        points.push([bump_construct.start.X, bump_construct.start.Y]);
        points.push([bump_construct.top.X, bump_construct.top.Y]);
        points.push([bump_construct.end.X, bump_construct.end.Y]);
      }
    }
  }
}
