/// ACTION ///
import { Action } from "api/action_types/price_lists";
/// ACTION TYPE ///
import { ActionType } from "api/action_types/price_lists";
/// TYPES ///
import { PriceList } from "api/types/price_list/price_list";
import { IPriceControl } from "api/types/price_list/price_control";
import { IMiter } from "api/types/price_list/edge/miter";
import { ISplash } from "api/types/price_list/edge/splash";
import { IWaterfall } from "api/types/price_list/edge/waterfall";
import { IFabricationCharge } from "api/types/price_list/fabrication_charge";
import { ITemplatingCharge } from "api/types/price_list/templating_charge";
import { IInstallationCharge } from "api/types/price_list/installation_charge";
import { IFinishedEdge } from "api/types/price_list/edge/finished";
import { IApplianceEdge } from "api/types/price_list/edge/appliance";
import { IFaucetCutout } from "api/types/price_list/cutout/faucet";
import { IOutletCutout } from "api/types/price_list/cutout/outlet";
import { ISinkCutout } from "api/types/price_list/cutout/sink";
import { ICooktopCutout } from "api/types/price_list/cutout/cooktop";
import { IClippedCorner } from "api/types/price_list/corner/clipped";
import { INotchedCorner } from "api/types/price_list/corner/notched";
import { IBumpedOutCorner } from "api/types/price_list/corner/bumped_out";
import { IOutsideRadiusCorner } from "api/types/price_list/corner/outside_radius";
import { IInsideRadiusCorner } from "api/types/price_list/corner/inside_radius";
import { IInsideDiagonalCorner } from "api/types/price_list/corner/inside_diagonal";
import { IRecessedDiagonalCorner } from "api/types/price_list/corner/recessed_diagonal";
import { IBumpIn } from "api/types/price_list/bump/in";
import { IBumpOut } from "api/types/price_list/bump/out";
import { IBumpInArc } from "api/types/price_list/bump/in_arc";
import { IBumpOutArc } from "api/types/price_list/bump/out_arc";
import { IFullArc } from "api/types/price_list/bump/full_arc";
import { StoneCounterRemovalPrice } from "api/types/price_list/countertop_removal/stone";
import { LaminateCounterRemovalPrice } from "api/types/price_list/countertop_removal/laminate";


interface State {
  price_list: PriceList,
  price_lists: PriceList[],
  miters: IMiter[],
  splashes: ISplash[],
  waterfalls: IWaterfall[],
  fabrication_charges: IFabricationCharge[],
  installation_charges: IInstallationCharge[],
  templating_charges: ITemplatingCharge[],
  finished_edges: IFinishedEdge[],
  appliance_edges: IApplianceEdge[],
  faucet_cutouts: IFaucetCutout[],
  outlet_cutouts: IOutletCutout[],
  sink_cutouts: ISinkCutout[],
  cooktop_cutouts: ICooktopCutout[],
  clipped_corners: IClippedCorner[],
  notched_corners: INotchedCorner[],
  bumped_out_corners: IBumpedOutCorner[],
  radius_corners: IOutsideRadiusCorner[],
  inside_radius_corners: IInsideRadiusCorner[],
  inside_diagonal_corners: IInsideDiagonalCorner[],
  recessed_diagonal_corners: IRecessedDiagonalCorner[],
  bump_ins: IBumpIn[],
  bump_outs: IBumpOut[],
  bump_in_arcs: IBumpInArc[],
  bump_out_arcs: IBumpOutArc[],
  full_arcs: IFullArc[],
  stone_countertop_removals: StoneCounterRemovalPrice,
  laminate_countertop_removals: LaminateCounterRemovalPrice,
  price_controls: IPriceControl,
  loading: boolean,
  updating: boolean,
  error: string
}

const initialState = {
  price_list: {},
  price_lists: [],
  price_controls: {},
  miters: [],
  splashes: [],
  waterfalls: [],
  fabrication_charges: [],
  installation_charges: [],
  templating_charges: [],
  finished_edges: [],
  appliance_edges: [],
  faucet_cutouts: [],
  outlet_cutouts: [],
  sink_cutouts: [],
  cooktop_cutouts: [],
  clipped_corners: [],
  notched_corners: [],
  bumped_out_corners: [],
  radius_corners: [],
  inside_radius_corners: [],
  inside_diagonal_corners: [],
  recessed_diagonal_corners: [],
  bump_ins: [],
  bump_outs: [],
  bump_in_arcs: [],
  bump_out_arcs: [],
  full_arcs: [],
  stone_countertop_removals: {},
  laminate_countertop_removals: {},
  loading: false,
  updating: false,
  error: ""
}

export const priceListReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_PRICE_LIST:
      return {
        ...state,
        price_list: action.price_list,
        loading: false,
        error: ""
      }
    case ActionType.GET_PRICE_LISTS:
      return {
        ...state,
        price_lists: action.price_lists,
        loading: false,
        error: ""
      }
    case ActionType.GET_PRICE_CONTROLS:
      return {
        ...state,
        price_controls: action.price_controls
      }
    case ActionType.GET_MITER_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        miters: action.miters
      }
    case ActionType.GET_SPLASH_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        splashes: action.splashes
      }
    case ActionType.GET_WATERFALL_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        waterfalls: action.waterfalls
      }
    case ActionType.GET_FABRICATION_CHARGE_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        fabrication_charges: action.fabrication_charges
      }
    case ActionType.GET_INSTALLATION_CHARGE_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        installation_charges: action.installation_charges
      }
    case ActionType.GET_TEMPLATING_CHARGE_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        templating_charges: action.templating_charges
      }

    case ActionType.GET_FINISHED_EDGE_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        finished_edges: action.finished_edges
      }
    case ActionType.GET_APPLIANCE_EDGE_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        appliance_edges: action.appliance_edges
      }
    case ActionType.GET_FAUCET_CUTOUT_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        faucet_cutouts: action.faucet_cutouts
      }
    case ActionType.GET_OUTLET_CUTOUT_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        outlet_cutouts: action.outlet_cutouts
      }
    case ActionType.GET_SINK_CUTOUT_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        sink_cutouts: action.sink_cutouts
      }
    case ActionType.GET_COOKTOP_CUTOUT_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        cooktop_cutouts: action.cooktop_cutouts
      }
    case ActionType.GET_CLIPPED_CORNER_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        clipped_corners: action.clipped_corners
      }
    case ActionType.GET_NOTCHED_CORNER_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        notched_corners: action.notched_corners
      }
    case ActionType.GET_BUMPED_OUT_CORNER_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        bumped_out_corners: action.bumped_out_corners
      }
    case ActionType.GET_OUTSIDE_RADIUS_CORNER_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        radius_corners: action.outside_radius_corners
      }
    case ActionType.GET_INSIDE_RADIUS_CORNER_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        inside_radius_corners: action.inside_radius_corners
      }
    case ActionType.GET_INSIDE_DIAGONAL_CORNER_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        inside_diagonal_corners: action.inside_diagonal_corners
      }
    case ActionType.GET_RECESSED_DIAGONAL_CORNER_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        recessed_diagonal_corners: action.recessed_diagonal_corners
      }
    case ActionType.GET_BUMP_IN_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        bump_ins: action.bump_ins
      }
    case ActionType.GET_BUMP_OUT_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        bump_outs: action.bump_outs
      }
    case ActionType.GET_BUMP_IN_ARC_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        bump_in_arcs: action.bump_in_arcs
      }
    case ActionType.GET_BUMP_OUT_ARC_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        bump_out_arcs: action.bump_out_arcs
      }
    case ActionType.GET_FULL_ARC_PRICES:
      return {
        ...state,
        loading: false,
        error: "",
        full_arcs: action.full_arcs
      }
    case ActionType.GET_STONE_COUNTERTOP_REMOVAL_PRICE:
      return {
        ...state,
        loading: false,
        error: "",
        stone_countertop_removals: action.stone_countertop_removals
      }
    case ActionType.GET_LAMINATE_COUNTERTOP_REMOVAL_PRICE:
      return {
        ...state,
        loading: false,
        error: "",
        laminate_countertop_removals: action.laminate_countertop_removals
      }
    case ActionType.UPDATE_PRICE_LIST:
      return {
        ...state,
        updating: false,
        error: "",
        price_list: action.price_list
      }
    case ActionType.UPDATE_PRICE_CONTROLS:
      return {
        ...state,
        updating: false,
        error: "",
        price_controls: action.price_controls
      }
    case ActionType.UPDATE_SPLASH_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_FABRICATION_CHARGE_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_INSTALLATION_CHARGE_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_TEMPLATING_CHARGE_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_FINISHED_EDGE_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_APPLIANCE_EDGE_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_FAUCET_CUTOUT_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_OUTLET_CUTOUT_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_SINK_CUTOUT_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_COOKTOP_CUTOUT_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_INSIDE_RADIUS_CORNER_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_OUTSIDE_RADIUS_CORNER_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_FULL_ARC_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_INSIDE_DIAGONAL_CORNER_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_RECESSED_DIAGONAL_CORNER_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_BUMPED_OUT_CORNER_PRICE:
      return {
        updating: false,
        ...state,
        error: ""
      }
    case ActionType.UPDATE_STONE_COUNTERTOP_REMOVAL_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.UPDATE_LAMINATE_COUNTERTOP_REMOVAL_PRICE:
      return {
        ...state,
        updating: false,
        error: ""
      }
    case ActionType.PRICE_LIST_LOADING:
      return {
        ...state,
        loading: true
      }
    case ActionType.PRICE_LIST_UPDATING:
      return {
        ...state,
        updating: true
      }
    case ActionType.PRICE_LIST_ERROR:
      return {
        ...state,
        updating: false,
        loading: false,
        error: action.payload
      }
    default:
      return state;
  }
}



