import { ISplash } from "api/types/price_list/edge/splash";

export enum ActionType {
  GET_SPLASH_PRICES = "GET_SPLASH_PRICES",
  UPDATE_SPLASH_PRICE = "UPDATE_SPLASH_PRICE",
}

interface IGetSplashPrices {
  type: ActionType.GET_SPLASH_PRICES
  splashes: ISplash[]
}

interface IUpdateSplashPrice {
  type: ActionType.UPDATE_SPLASH_PRICE,
  splash: ISplash
}

export type Action =
  IGetSplashPrices |
  IUpdateSplashPrice;
