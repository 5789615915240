import { ISinkCutout } from "api/types/price_list/cutout/sink";

export enum ActionType {
  GET_SINK_CUTOUT_PRICES = "GET_SINK_CUTOUT_PRICES",
  UPDATE_SINK_CUTOUT_PRICE = "UPDATE_SINK_CUTOUT_PRICE"
}

interface IGetSinkCutouts {
  type: ActionType.GET_SINK_CUTOUT_PRICES
  sink_cutouts: ISinkCutout[]
}

interface IUpdateSinkCutout {
  type: ActionType.UPDATE_SINK_CUTOUT_PRICE,
  sink_cutout: ISinkCutout
}

export type Action =
  IGetSinkCutouts |
  IUpdateSinkCutout;
