/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopCooktopCutout } from "api/actions/price_list";
/// TYPES ///
import { ICooktopCutout } from "api/types/price_list/cutout/cooktop";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  cooktop_cutout: ICooktopCutout
}

export default function CooktopCutout({ index, last, cooktop_cutout }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={cooktop_cutout?.price ?? 0}
      title={getMaterialHeightDisplay(cooktop_cutout?.material_thickness ?? "")}
      end_text="$"
      end_padding="45px"
      update={price => dispatch(updateShopCooktopCutout({ price: price }, cooktop_cutout.id))} />
  )
}
