import { IRecessedDiagonalCorner } from "api/types/price_list/corner/recessed_diagonal";

export enum ActionType {
  GET_RECESSED_DIAGONAL_CORNER_PRICES = "GET_RECESSED_DIAGONAL_CORNER_PRICES",
  UPDATE_RECESSED_DIAGONAL_CORNER_PRICE = "UPDATE_RECESSED_DIAGONAL_CORNER_PRICE"
}

interface IGetRecessedDiagonalCorners {
  type: ActionType.GET_RECESSED_DIAGONAL_CORNER_PRICES
  recessed_diagonal_corners: IRecessedDiagonalCorner[]
}

interface IUpdateRecessedDiagonalCorner {
  type: ActionType.UPDATE_RECESSED_DIAGONAL_CORNER_PRICE,
  recessed_diagonal_corner: IRecessedDiagonalCorner
}

export type Action = IGetRecessedDiagonalCorners | IUpdateRecessedDiagonalCorner;
