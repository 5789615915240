/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { clearMarketOrder } from "api/actions/order/market_order";
import { getMarketOrder } from "api/actions/order/market_order";
import { getOrderAreas } from "api/actions/order/area";
import { getOrderCounters } from "api/actions/order/counter";
import { getOrderSlabs } from "api/actions/order/slab";
import { openSignatureMessage } from "api/actions/signature";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import LoadingPage from "components/universal/loading_page";
import EmailSignatureRequest from "components/universal/dialog/signature";
import OrderCountersDiagram from "components/universal/diagrams/order_counter/areas";
import OrderSlabsDiagram from "components/universal/diagrams/order_cut_shape/areas";
import OrderSummary from "components/universal/order/summary";
import POHeader from "components/universal/order/po_header";
import SignatureArea from "components/universal/signature";
// FM //
import { motion } from "framer-motion";
/// ICONS ///
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { faSignature } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { order_url } from "components/navigation/endpoints";
import { print_url } from "components/navigation/endpoints";
import { store_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { store_order_list } from "values/breadcrumbs/order/store";
import { store_order } from "values/breadcrumbs/order/store";

export default function StoreOrder() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    areas,
    areas_loaded,
    counters,
    counters_loaded,
    order,
    slabs,
    slabs_loaded
  } = useAppSelector((state) => state.order);
  const { message_open } = useAppSelector((state) => state.signature);
  const [open_signature, setOpenSignature] = useState<boolean>(false);


  useEffect(() => {
    dispatch(setBreadcrumbs([store_order_list, { ...store_order, label: order?.name ?? "Order", url: store_order.url.replace("id", id) }]));
  }, [dispatch, order?.name, id]);

  useEffect(() => {
    if (id) {
      dispatch(getMarketOrder(id));
      dispatch(getOrderCounters(id));
      dispatch(getOrderAreas(id));
      dispatch(getOrderSlabs(id));
    }
    return () => {
      dispatch(clearMarketOrder());
    }
  }, [dispatch, id]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={!areas_loaded || !counters_loaded || !slabs_loaded}>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" sx={{ paddingBottom: "10px" }}>
              <BaseButton text="Signature" icon={faSignature} clickEvent={() => setOpenSignature(true)} />
              <BaseButton text="Print" icon={faPrint} clickEvent={() => window.open(`/${store_url}/${order_url}/${id}/${print_url}`, '_blank')} />
            </Box>
          </Grid>
          <POHeader
            invoice_number={order?.order_number ?? ""}
            seller={order?.fabricator_data ?? {}}
            customer={order?.vendor_data ?? {}}
            job={order?.job_data ?? {}}
            created={order?.created ?? ""} />
          <OrderSummary order={order} areas={areas} />
          <OrderCountersDiagram areas={areas} counters={counters} counters_loaded={counters_loaded && areas_loaded} />
          <OrderSlabsDiagram slabs={slabs} areas={areas} shapes_loaded={areas_loaded && slabs_loaded} />
          <Grid item xs={12}>
            <Typography variant="h6">
              Terms & Conditions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: "20px" }}>
            <Typography variant="body2">
              {order?.vendor_data?.payment_terms ?? ""}
            </Typography>
          </Grid>
          <SignatureArea
            signature={order?.signature_image ?? null}
            name={`${order?.end_customer_data?.first_name ?? ""} ${order?.end_customer_data?.last_name ?? ""}`}
            date={order?.signature_date ?? null} />
          <Grid item xs={12}>
            <EmailSignatureRequest
              open={open_signature}
              setOpen={setOpenSignature}
              default_email={order?.end_customer_data?.email ?? ""}
              name={`${order?.end_customer_data.first_name ?? ""} ${order?.end_customer_data?.last_name ?? ""}`}
              customer={order?.end_customer ?? null}
              self_order_id={order?.id ?? 0}
              exists={order?.signature_image ? true : false} />
          </Grid>
        </Grid>
        <Snackbar
          open={message_open}
          autoHideDuration={5000}
          onClose={() => dispatch(openSignatureMessage(false))}
          message="Signature sent to customer successfully." />
      </LoadingPage>
    </motion.div>
  )
}
