/// TYPES ///
import { MotionStyle } from "framer-motion";
import { TargetAndTransition } from "framer-motion";
/// COLORS ///
import { dark_grey_color, primary_color } from "./style";

export const mat_tile_style: MotionStyle = {
  color: dark_grey_color,
  width: "175px",
  height: "175px",
  border: `2px solid ${dark_grey_color}`,
  borderRadius: "10px",
  padding: "10px",
  display: "flex",
  backgroundColor: "var(--mui-palette-background-paper)",
  flexDirection: "column",
  alignItems: "center",
  gap: "55px"
}

export const mat_tile_anim: TargetAndTransition = {
  color: primary_color,
  borderColor: primary_color,
  transition: {
    ease: "easeIn",
    duration: 0.2,
    type: "tween"
  }
}
