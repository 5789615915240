import { IClippedCorner } from "api/types/price_list/corner/clipped";

export enum ActionType {
  GET_CLIPPED_CORNER_PRICES = "GET_CLIPPED_CORNER_PRICES",
  UPDATE_CLIPPED_CORNER_PRICE = "UPDATE_CLIPPED_CORNER_PRICE"
}

interface IGetClippedCorners {
  type: ActionType.GET_CLIPPED_CORNER_PRICES
  clipped_corners: IClippedCorner[]
}

interface IUpdateClippedCorner {
  type: ActionType.UPDATE_CLIPPED_CORNER_PRICE,
  clipped_corner: IClippedCorner
}

export type Action = IGetClippedCorners | IUpdateClippedCorner;
