/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopFaucetCutout } from "api/actions/price_list";
/// TYPES ///
import { IFaucetCutout } from "api/types/price_list/cutout/faucet";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  faucet_cutout: IFaucetCutout
}

export default function FaucetCutout({ index, last, faucet_cutout }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={faucet_cutout?.price ?? 0}
      title={getMaterialHeightDisplay(faucet_cutout?.material_thickness ?? "")}
      end_text="$"
      end_padding="45px"
      update={price => dispatch(updateShopFaucetCutout({ price: price }, faucet_cutout.id))} />
  )
}
