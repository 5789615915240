/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopBumpOut } from "api/actions/price_list";
/// TYPES ///
import { IBumpOut } from "api/types/price_list/bump/out";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  bump_out: IBumpOut
}

export default function BumpOut({ index, last, bump_out }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={bump_out?.price ?? 0}
      title={getMaterialHeightDisplay(bump_out?.material_thickness ?? "")}
      end_text="$"
      end_padding="45px"
      update={price => dispatch(updateShopBumpOut({ price: price }, bump_out.id))} />
  )
}
