/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopBumpedOutCorner } from "api/actions/price_list";
/// TYPES ///
import { IBumpedOutCorner } from "api/types/price_list/corner/bumped_out";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  bumped_out_corner: IBumpedOutCorner
}

export default function BumpedOutCorner({ index, last, bumped_out_corner }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={bumped_out_corner?.price ?? 0}
      title={getMaterialHeightDisplay(bumped_out_corner?.material_thickness ?? "")}
      end_text="$"
      end_padding="45px"
      update={price => dispatch(updateShopBumpedOutCorner({ price: price }, bumped_out_corner.id))} />
  )
}
