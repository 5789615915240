import { IBumpInArc } from "api/types/price_list/bump/in_arc";

export enum ActionType {
  GET_BUMP_IN_ARC_PRICES = "GET_BUMP_IN_ARC_PRICES",
  UPDATE_BUMP_IN_ARC_PRICE = "UPDATE_BUMP_IN_ARC_PRICE"
}

interface IGetBumpInArcs {
  type: ActionType.GET_BUMP_IN_ARC_PRICES
  bump_in_arcs: IBumpInArc[]
}

interface IUpdateBumpInArc {
  type: ActionType.UPDATE_BUMP_IN_ARC_PRICE,
  bump_in_arc: IBumpInArc
}

export type Action = IGetBumpInArcs | IUpdateBumpInArc;
