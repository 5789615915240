/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopBumpOutArc } from "api/actions/price_list";
/// TYPES ///
import { IBumpOutArc } from "api/types/price_list/bump/out_arc";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  bump_out_arc: IBumpOutArc
}

export default function BumpOutArc({ index, last, bump_out_arc }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={bump_out_arc?.price ?? 0}
      title={getMaterialHeightDisplay(bump_out_arc?.material_thickness ?? "")}
      end_text="$"
      end_padding="45px"
      update={price => dispatch(updateShopBumpOutArc({ price: price }, bump_out_arc.id))} />
  )
}
