/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopSplash } from "api/actions/price_list";
/// TYPES ///
import { ISplash } from "api/types/price_list/edge/splash";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  splash: ISplash
}

export default function Splash({ index, last, splash }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={splash?.price ?? 0}
      title={getMaterialHeightDisplay(splash?.material_thickness ?? "")}
      end_text="$/LIN"
      update={price => dispatch(updateShopSplash({ price: price }, splash.id))} />
  )
}
