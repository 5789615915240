/// MUI ///
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// STYLES ///
import { row_header_style } from "styles/universal/row";
import { row_text_item_style } from "styles/universal/row";

export default function PriceListsHeader() {
  return (
    <Grid item xs={12} sx={row_header_style}>
      <Typography variant="body2" sx={row_text_item_style}>
        <b>Name</b>
      </Typography>
    </Grid>
  )
}
