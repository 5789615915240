/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// TYPES ///
import { IPriceControl } from "api/types/price_list/price_control";
/// ACTIONS ///
import { getPriceList } from "api/actions/price_list";
import { getPriceControls } from "api/actions/price_list";
import { updatePriceControls } from "api/actions/price_list";
import { getShopBumpedOutCorners } from "api/actions/price_list";
import { getShopClippedCorners } from "api/actions/price_list";
import { getShopInsideDiagonalCorners } from "api/actions/price_list";
import { getShopInsideRadiusCorners } from "api/actions/price_list";
import { getShopNotchedCorners } from "api/actions/price_list";
import { getShopOutsideRadiusCorners } from "api/actions/price_list";
import { getShopRecessedDiagonalCorners } from "api/actions/price_list";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BumpedOutCorner from "./bumped_out";
import ClippedCorner from "./clipped";
import InsideDiagonalCorner from "./inside_diagonal";
import InsideRadiusCorner from "./inside_radius";
import NotchedCorner from "./notched";
import OutsideRadiusCorner from "./outside_radius";
import RecessedDiagonalCorner from "./recessed_diagonal";
import Loading from "components/universal/loading";
import PageTitle from "components/universal/page/title";
import PriceControlSwitch from "components/price_lists/price_control/switch";
/// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { title_style } from "styles/quote_preview/section";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { list } from "values/breadcrumbs/market/pl";
import { overview } from "values/breadcrumbs/market/pl";

export default function CornerPriceList() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { price_list } = useAppSelector(state => state.price_list);
  const { bumped_out_corners } = useAppSelector(state => state.price_list);
  const { clipped_corners } = useAppSelector(state => state.price_list);
  const { inside_diagonal_corners } = useAppSelector(state => state.price_list);
  const { inside_radius_corners } = useAppSelector(state => state.price_list);
  const { notched_corners } = useAppSelector(state => state.price_list);
  const { radius_corners } = useAppSelector(state => state.price_list);
  const { recessed_diagonal_corners } = useAppSelector(state => state.price_list);
  const { price_controls } = useAppSelector(state => state.price_list);
  const { updating } = useAppSelector(state => state.price_list);
  const [update_price_control, setUpdatePriceControl] = useState<IPriceControl>({});

  useEffect(() => {
    setUpdatePriceControl(price_controls);
  }, [price_controls?.id]);

  useEffect(() => {
    if (!(price_list?.id) || price_list.id.toString() !== id) {
      dispatch(getPriceList(id));
    }
  }, [dispatch, id, price_list?.id]);

  useEffect(() => {
    if (id) {
      dispatch(getPriceControls());
      dispatch(getShopBumpedOutCorners(id));
      dispatch(getShopClippedCorners(id));
      dispatch(getShopInsideDiagonalCorners(id));
      dispatch(getShopInsideRadiusCorners(id));
      dispatch(getShopNotchedCorners(id));
      dispatch(getShopOutsideRadiusCorners(id));
      dispatch(getShopRecessedDiagonalCorners(id));
    }
    dispatch(setBreadcrumbs([list, { ...overview, url: overview.url.replace("id", id) }]));
  }, [id, dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <PageTitle title={`${price_list?.name} Corners` ?? ""} endProps={<div></div>} />
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Bumped Out Corners
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_bumped_out_corner}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_bumped_out_corner: checked });
                  dispatch(updatePriceControls({ use_bumped_out_corner: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_bumped_out_corner ?
              bumped_out_corners.map((bumped_out_corner, index) => <BumpedOutCorner key={index} index={index} bumped_out_corner={bumped_out_corner} last={index === bumped_out_corners.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Clipped Corners
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_clipped_corner}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_clipped_corner: checked });
                  dispatch(updatePriceControls({ use_clipped_corner: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_clipped_corner ?
              clipped_corners.map((clipped_corner, index) => <ClippedCorner key={index} index={index} clipped_corner={clipped_corner} last={index === clipped_corners.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Inside Diagonal Corners
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_inside_diagonal}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_inside_diagonal: checked });
                  dispatch(updatePriceControls({ use_inside_diagonal: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_inside_diagonal ?
              inside_diagonal_corners.map((inside_diagonal, index) => <InsideDiagonalCorner key={index} index={index} inside_diagonal_corner={inside_diagonal} last={index === inside_diagonal_corners.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Inside Radius Corners
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_inside_radius}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_inside_radius: checked });
                  dispatch(updatePriceControls({ use_inside_radius: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_inside_radius ?
              inside_radius_corners.map((inside_radius, index) => <InsideRadiusCorner key={index} index={index} inside_radius_corner={inside_radius} last={index === inside_radius_corners.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Notched Corners
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_notched_corner}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_notched_corner: checked });
                  dispatch(updatePriceControls({ use_notched_corner: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_notched_corner ?
              notched_corners.map((notched_corner, index) => <NotchedCorner key={index} index={index} notched_corner={notched_corner} last={index === notched_corners.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Radius Corners
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_outside_radius}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_outside_radius: checked });
                  dispatch(updatePriceControls({ use_outside_radius: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_outside_radius ?
              radius_corners.map((radius_corner, index) => <OutsideRadiusCorner key={index} index={index} outside_radius_corner={radius_corner} last={index === radius_corners.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Recessed Diagonal Corners
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_recessed_diagonal}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_recessed_diagonal: checked });
                  dispatch(updatePriceControls({ use_recessed_diagonal: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_recessed_diagonal ?
              recessed_diagonal_corners.map((recessed_diagonal, index) => <RecessedDiagonalCorner key={index} index={index} recessed_diagonal_corner={recessed_diagonal} last={index === recessed_diagonal_corners.length - 1} />) : null
          }
        </Grid>
      </Grid>
      <Loading loading={updating} />
    </motion.div>
  )
}
