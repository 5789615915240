/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { setShopView } from "api/actions/authentication/local/shop_view";
/// COMPONENTS ///
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// RS ///
import Switch from "react-switch";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faShop } from "@fortawesome/free-solid-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { container_style } from "styles/switch";
import { label_style } from "styles/switch";
import { checked_ico_style } from "styles/switch";
import { unchecked_ico_style } from "styles/switch";
/// COLORS ///
import { primary_color } from "styles/style";
import { third_color } from "styles/style";

export default function AccountSwitch() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shop_view, user } = useAppSelector((state) => state.authentication);

  const toggleView = (checked: boolean) => {
    dispatch(setShopView(checked));
    navigate("/");
  }

  if (!user.is_fabricator) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" sx={container_style}>
      <Switch
        offColor={third_color}
        onColor={primary_color}
        height={24}
        uncheckedIcon={<FontAwesomeIcon icon={faDollarSign} style={unchecked_ico_style} />}
        checkedIcon={<FontAwesomeIcon icon={faShop} style={checked_ico_style} />}
        onChange={toggleView}
        checked={shop_view} />
      <Typography variant="h6" sx={label_style}>
        {shop_view ? "Shop" : "Market"}
      </Typography>
    </Stack>
  )
}
