/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopBumpIn } from "api/actions/price_list";
/// TYPES ///
import { IBumpIn } from "api/types/price_list/bump/in";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  bump_in: IBumpIn
}

export default function BumpIn({ index, last, bump_in }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={bump_in?.price ?? 0}
      title={getMaterialHeightDisplay(bump_in?.material_thickness ?? "")}
      end_text="$"
      end_padding="45px"
      update={price => dispatch(updateShopBumpIn({ price: price }, bump_in.id))} />
  )
}
