import { IFinishedEdge } from "api/types/price_list/edge/finished";

export enum ActionType {
  GET_FINISHED_EDGE_PRICES = "GET_FINISHED_EDGE_PRICES",
  UPDATE_FINISHED_EDGE_PRICE = "UPDATE_FINISHED_EDGE_PRICE"
}

interface IGetFinishedEdgePrices {
  type: ActionType.GET_FINISHED_EDGE_PRICES
  finished_edges: IFinishedEdge[]
}

interface IUpdateFinishedEdgePrice {
  type: ActionType.UPDATE_FINISHED_EDGE_PRICE,
  finished_edge: IFinishedEdge
}

export type Action =
  IGetFinishedEdgePrices |
  IUpdateFinishedEdgePrice;
