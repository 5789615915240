/// ROUTES ///
export const dashboard_url: string = "/";
export const end_customer_url: string = "account";
export const event_url: string = "events";
export const forgot_password_url: string = "forgot_password";
export const job_url: string = "job";
export const home_url: string = "/";
export const login_url: string = "login";
export const market_url: string = "market";
export const material_url: string = "material";
export const order_url: string = "order";
export const org_url: string = "organization";
export const payment_setup_url: string = "payment_setup";
export const price_list_url: string = "price_list";
export const print_url: string = "print";
export const profile_url: string = "profile";
export const quote_url: string = "quote";
export const quote_preview_url: string = "quote_preview";
export const shop_url: string = "shop";
export const shops_url: string = "shops";
export const store_url: string = "store";
export const stores_url: string = "stores";
export const wo_url: string = "wo";


/// SUB ROUTES ///
export const create_url: string = "create";
export const update_url: string = "update";
export const detail_url: string = "d";
export const filter_url: string = "filter";
export const preview_url: string = "preview";
export const delete_url: string = "delete";
export const assign_url: string = "assign";
export const billing_url: string = "b";
export const terms_url: string = "terms";
export const change_password_url: string = "change_password";
export const week_url: string = "week";
export const day_url: string = "day";
export const month_url: string = "month";

/// PRICE LIST ROUTES ///
export const bump_url: string = "bump";
export const cutout_url: string = "cutout";
export const corner_url: string = "corner";
export const edges_url: string = "edge";
export const labor_url: string = "labor_charges";
export const universal_price_url: string = "universal_prices";
