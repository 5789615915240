/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopRecessedDiagonalCorner } from "api/actions/price_list";
/// TYPES ///
import { IRecessedDiagonalCorner } from "api/types/price_list/corner/recessed_diagonal";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  recessed_diagonal_corner: IRecessedDiagonalCorner
}

export default function RecessedDiagonalCorner({ index, last, recessed_diagonal_corner }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={recessed_diagonal_corner?.price ?? 0}
      title={getMaterialHeightDisplay(recessed_diagonal_corner?.material_thickness ?? "")}
      end_text="$"
      end_padding="45px"
      update={price => dispatch(updateShopRecessedDiagonalCorner({ price: price }, recessed_diagonal_corner.id))} />
  )
}
