import { IBumpIn } from "api/types/price_list/bump/in";

export enum ActionType {
  GET_BUMP_IN_PRICES = "GET_BUMP_IN_PRICES",
  UPDATE_BUMP_IN_PRICE = "UPDATE_BUMP_IN_PRICE"
}

interface IGetBumpIns {
  type: ActionType.GET_BUMP_IN_PRICES
  bump_ins: IBumpIn[]
}

interface IUpdateBumpIn {
  type: ActionType.UPDATE_BUMP_IN_PRICE,
  bump_in: IBumpIn
}

export type Action = IGetBumpIns | IUpdateBumpIn;
