/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopOutletCutout } from "api/actions/price_list";
/// TYPES ///
import { IOutletCutout } from "api/types/price_list/cutout/outlet";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  outlet_cutout: IOutletCutout
}

export default function OutletCutout({ index, last, outlet_cutout }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={outlet_cutout?.price ?? 0}
      title={getMaterialHeightDisplay(outlet_cutout?.material_thickness ?? "")}
      end_text="$"
      end_padding="45px"
      update={price => dispatch(updateShopOutletCutout({ price: price }, outlet_cutout.id))} />
  )
}
