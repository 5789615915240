import { IFullArc } from "api/types/price_list/bump/full_arc";

export enum ActionType {
  GET_FULL_ARC_PRICES = "GET_FULL_ARC_PRICES",
  UPDATE_FULL_ARC_PRICE = "UPDATE_FULL_ARC_PRICE"
}

interface IGetFullArcs {
  type: ActionType.GET_FULL_ARC_PRICES
  full_arcs: IFullArc[]
}

interface IUpdateFullArc {
  type: ActionType.UPDATE_FULL_ARC_PRICE,
  full_arc: IFullArc
}

export type Action = IGetFullArcs | IUpdateFullArc;
