/// HOOKS ///
import { useNavigate } from "react-router-dom";
/// FM ///
import { motion } from "framer-motion";
/// MUI ///
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// TYPES ///
import { PriceList } from "api/types/price_list/price_list";
/// URLS ///
import { price_list_url } from "components/navigation/endpoints";
/// ICONS ///
import { faCompress } from "@fortawesome/free-solid-svg-icons";
import { faDiamond } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { mat_tile_style } from "styles/material";
/// FA ///
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ANIMATION ///
import { mat_tile_anim } from "styles/material";
/// VALUES ///
import { getMaterialTypeDisplay } from "values/material";
import { QUARTZ } from "values/material";
import { ULTRA_COMPACT } from "values/material";
import { NATURAL_STONE } from "values/material";

interface Props {
  item: PriceList
}

export default function PriceListItem({ item }: Props) {
  const navigate = useNavigate();

  const getIcon = (material_type: string): IconDefinition => {
    if (material_type === QUARTZ) {
      return faDiamond;
    }
    else if (material_type === ULTRA_COMPACT) {
      return faCompress;

    }
    else if (material_type === NATURAL_STONE) {
      return faSquare;

    }
    else {
      return faDiamond;
    }
  }

  return (
    <motion.button
      initial={{ opacity: 0, right: 20 }}
      animate={{ opacity: 1, right: 0, transition: { delay: 0.2 } }}
      style={mat_tile_style}
      whileHover={mat_tile_anim}
      onClick={() => navigate(`/${price_list_url}/${item.id}`)}>
      <Typography variant="h6" sx={{ marginTop: "15px" }}>
        {getMaterialTypeDisplay(item?.material_type ?? "")}
      </Typography>
      <FontAwesomeIcon icon={getIcon(item?.material_type ?? "")} size="2xl" />
    </motion.button>
  )
}
