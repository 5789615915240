/// REACT ///
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// MUI ///
import InputAdornment from "@mui/material/InputAdornment";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
/// FM ///
import { motion } from "framer-motion";
/// LODASH ///
import { debounce } from "lodash";
/// STYLES ///
import { motion_div_style } from "../styles";
import { stack_style } from "../styles";
import { textfield_style } from "../styles";
import { adorn_type_style } from "../styles";

interface Props {
  index: number,
  last: boolean,
  original_price: number,
  title: string,
  end_text: string,
  end_padding?: string,
  update: (price: number) => void
}

export default function PriceRow({
  index,
  last,
  original_price,
  title,
  end_text,
  end_padding = "0px",
  update
}: Props) {
  const [current_price_display, setCurrentPriceDisplay] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setCurrentPriceDisplay(original_price.toString());
  }, [original_price]);

  const debouncedUpdate = useCallback(debounce(function (price: number) {
    update(price);
  }, 1000), []);

  const handlePrice = (price: string) => {
    setCurrentPriceDisplay(price);
    if(isNaN(Number(price)) || price === ""){
      console.log("not a number")
      setError(true);
    }
    else{
      debouncedUpdate(Number(price));
      setError(false);
    }
  }

  return (
    <Grid item xs={12}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.4 * index } }} style={motion_div_style}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={stack_style(last)}>
          <Typography variant="body2">
            {title}
          </Typography>
          <TextField
            sx={textfield_style}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="start">
                  <Typography variant="body2" sx={[adorn_type_style, { paddingLeft: end_padding }]}>
                    {end_text}
                  </Typography>
                </InputAdornment>
              ),
            }}
            error={error}
            helperText={error ? "Enter a valid number" : ""}
            variant="filled"
            onChange={e => handlePrice(e.target.value)}
            value={current_price_display} />
        </Stack>
      </motion.div>
    </Grid>
  )
}

