import { ITemplatingCharge } from "api/types/price_list/templating_charge";

export enum ActionType {
  GET_TEMPLATING_CHARGE_PRICES = "GET_TEMPLATING_CHARGE_PRICES",
  UPDATE_TEMPLATING_CHARGE_PRICE = "UPDATE_TEMPLATING_CHARGE_PRICE"
}

interface IGetTemplatingChargePrices {
  type: ActionType.GET_TEMPLATING_CHARGE_PRICES
  templating_charges: ITemplatingCharge[]
}

interface IUpdateTemplatingChargePrice {
  type: ActionType.UPDATE_TEMPLATING_CHARGE_PRICE,
  templating_charge: ITemplatingCharge
}

export type Action =
  IGetTemplatingChargePrices |
  IUpdateTemplatingChargePrice;
