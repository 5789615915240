/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { createShopMaterial } from "api/actions/shop_material";
import { resetCreateShopMaterialSuccess } from "api/actions/shop_material";
/// TYPES ///
import { IShopMaterial } from "api/types/shop_material";
import { IShopMaterialError } from "api/types/shop_material";
/// MUI ///
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
import StyledRadio from "components/universal/styled_radio";
/// ICONS ///
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
/// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION STYLES ///
import { m_init_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { page_trans } from "motion/page";
/// VALUES ///
import { QUARTZ } from "values/material";
import { NATURAL_STONE } from "values/material";
import { ULTRA_COMPACT } from "values/material";
import { CM12 } from "values/material";
import { CM2 } from "values/material";
import { CM3 } from "values/material";
import { CM1 } from "values/material";
import { CM60 } from "values/material";
import { CM08 } from "values/material";
import { getMaterialHeightDisplay } from "values/material";

export default function CreateShopMaterial() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { material: created_material } = useAppSelector(state => state.shop_material);
  const { create_success } = useAppSelector(state => state.shop_material);
  const [errors, setErrors] = useState<IShopMaterialError>({});
  const [material, setMaterial] = useState<IShopMaterial>({ taxable: true });

  useEffect(() => {
    if (create_success) {
      dispatch(resetCreateShopMaterialSuccess());
      navigate(-1);
    }

  }, [dispatch, navigate, create_success, created_material?.id]);

  const save = () => {
    let error: boolean = false;
    let material_errors: IShopMaterialError = {};

    if (!material?.name || material.name === "") {
      material_errors.name_error = "Enter a Material Name";
      material_errors.name_has_error = true;
      error = true;
    }

    if (!material?.material_type || material.material_type === "") {
      material_errors.material_type_error = "Choose a Material Typ";
      material_errors.material_type_has_error = true;
      error = true;
    }

    if (!material?.thickness || material.thickness === "") {
      material_errors.thickness_error = "Choose a Thickness Error";
      material_errors.thickness_has_error = true;
      error = true;
    }

    setErrors(material_errors);

    if (error) {
      return;
    }

    dispatch(createShopMaterial(material));
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container>
        <PageTitle title="Create Material" endProps={
          <Box>
            <BaseButton clickEvent={() => navigate(-1)} text="Cancel" icon={faXmark} />
            <BaseButton clickEvent={save} text="Save" icon={faSave} />
          </Box>
        } />
        <Grid item xs={12}>
          <Box style={header_style}></Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column" sx={{ gap: "10px", paddingTop: "10px" }}>
            <TextField
              label="Material Name"
              fullWidth
              value={material?.name ?? ""}
              error={errors?.name_has_error}
              helperText={errors?.name_error}
              onChange={e => setMaterial({ ...material, name: e.target.value })} />
            <TextField
              fullWidth
              label="Material Type"
              select
              value={material?.material_type ?? ""}
              error={errors?.material_type_has_error}
              helperText={errors?.material_type_error}
              onChange={e => setMaterial({ ...material, material_type: e.target.value })}
            >
              <MenuItem key="" value="">
              </MenuItem>
              <MenuItem key={QUARTZ} value={QUARTZ}>
                Quartz
              </MenuItem>
              <MenuItem key={NATURAL_STONE} value={NATURAL_STONE}>
                Natural Stone
              </MenuItem>
              <MenuItem key={ULTRA_COMPACT} value={ULTRA_COMPACT}>
                Ultra Compact
              </MenuItem>
            </TextField>
            <TextField
              fullWidth
              label="Thickness"
              select
              value={material?.thickness ?? ""}
              error={errors?.thickness_has_error}
              helperText={errors?.thickness_error}
              onChange={e => setMaterial({ ...material, thickness: e.target.value })}
            >
              <MenuItem key="" value="">
              </MenuItem>
              <MenuItem key={CM12} value={CM12}>
                {getMaterialHeightDisplay(CM12)}
              </MenuItem>
              <MenuItem key={CM2} value={CM2}>
                {getMaterialHeightDisplay(CM2)}
              </MenuItem>
              <MenuItem key={CM3} value={CM3}>
                {getMaterialHeightDisplay(CM3)}
              </MenuItem>
              <MenuItem key={CM1} value={CM1}>
                {getMaterialHeightDisplay(CM1)}
              </MenuItem>
              <MenuItem key={CM60} value={CM60}>
                {getMaterialHeightDisplay(CM60)}
              </MenuItem>
              <MenuItem key={CM08} value={CM08}>
                {getMaterialHeightDisplay(CM08)}
              </MenuItem>
            </TextField>
            <FormControl sx={{ paddingLeft: "5px", marginTop: "10px" }}>
              <FormLabel id="add-customer-radios">
                <Typography variant="body2">
                  Taxable
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                value={material?.taxable ? "true" : "false"}
                aria-labelledby="taxable-radios"
                name="taxable-radios"
                onChange={e => setMaterial({ ...material, taxable: e.target.value === "true" ? true : false })}
              >
                <FormControlLabel
                  value={"true"}
                  control={<StyledRadio />}
                  label={
                    <Typography variant="body2">
                      Yes
                    </Typography>
                  } />
                <FormControlLabel
                  value={"false"}
                  control={<StyledRadio />}
                  label={
                    <Typography variant="body2">
                      No
                    </Typography>
                  } />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </motion.div>
  )
}
