export const shop_price_list_url: string = "shop_price_list";
export const shop_installation_charge_url: string = "shop_installation_charge";
export const shop_templating_charge_url: string = "shop_templating_charge";
export const shop_fabrication_charge_url: string = "shop_fabrication_charge";
export const shop_appliance_edge_url: string = "shop_appliance_edge";
export const shop_finished_edge_url: string = "shop_finished_edge";
export const shop_miter_url: string = "shop_miter";
export const shop_miter_range_url: string = "shop_miter_range";
export const shop_splash_url: string = "shop_splash";
export const shop_splash_range_url: string = "shop_splash_range";
export const shop_waterfall_url: string = "shop_waterfall";
export const shop_waterfall_range_url: string = "shop_waterfall_range";
export const shop_cooktop_cutout_url: string = "shop_cooktop_cutout";
export const shop_cooktop_cutout_range_url: string = "shop_cooktop_cutout_range";
export const shop_faucet_cutout_url: string = "shop_faucet_cutout";
export const shop_outlet_cutout_url: string = "shop_outlet_cutout";
export const shop_sink_cutout_url: string = "shop_sink_cutout";
export const shop_sink_cutout_range_url: string = "shop_sink_cutout_range";
export const shop_laminate_countertop_removal_url: string = "shop_laminate_countertop_removal";
export const shop_stone_countertop_removal_url: string = "shop_stone_countertop_removal";
export const shop_bumped_out_corner_url: string = "shop_bumped_out_corner";
export const shop_clipped_corner_url: string = "shop_clipped_corner";
export const shop_inside_diagonal_corner_url: string = "shop_inside_diagonal_corner";
export const shop_inside_radius_corner_url: string = "shop_inside_radius_corner";
export const shop_inside_radius_corner_range_url: string = "shop_inside_radius_corner_range";
export const shop_notched_corner_url: string = "shop_notched_corner";
export const shop_outside_radius_corner_url: string = "shop_outside_radius_corner";
export const shop_outside_radius_corner_range_url: string = "shop_outside_radius_corner_range";
export const shop_recessed_diagonal_corner_url: string = "shop_recessed_diagonal_corner";
export const shop_bump_in_url: string = "shop_bump_in";
export const shop_bump_in_arc_url: string = "shop_bump_in_arc";
export const shop_bump_out_url: string = "shop_bump_out";
export const shop_bump_out_arc_url: string = "shop_bump_out_arc";
export const shop_full_arc_url: string = "shop_full_arc";
export const shop_price_control_url: string = "price_control";
