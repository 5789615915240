/// TYPES ///
import { IShopMaterial } from "api/types/shop_material";
import { IShopMaterialError } from "api/types/shop_material";
/// MUI ///
import Box from "@mui/material/Box";
import { Dialog } from "@mui/material";
import { DialogActions } from "@mui/material";
import { DialogContent } from "@mui/material";
import { DialogTitle } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import StyledRadio from "components/universal/styled_radio";
/// ICONS ///
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
/// STYLES ///
import { dialog_body_style } from "styles/dialog";
/// VALUES ///
import { getMaterialTypeDisplay } from "values/material";
import { CM12 } from "values/material";
import { CM2 } from "values/material";
import { CM3 } from "values/material";
import { CM1 } from "values/material";
import { CM60 } from "values/material";
import { CM08 } from "values/material";
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  title: string,
  button_text: string,
  material: IShopMaterial,
  setMaterial: (material: IShopMaterial) => void,
  errors: IShopMaterialError,
  save: () => void
}

export default function ShopMaterialDialog({
  open,
  setOpen,
  title,
  button_text,
  material,
  setMaterial,
  errors,
  save
}: Props) {

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={dialog_body_style}>
        <Box display="flex" flexDirection="column" sx={{ gap: "10px", paddingTop: "10px" }}>
          <TextField
            fullWidth
            label="Material Type"
            disabled
            value={getMaterialTypeDisplay(material?.material_type ?? "")}
          />
          <TextField
            label="Material Name"
            fullWidth
            value={material?.name ?? ""}
            error={errors?.name_has_error}
            helperText={errors?.name_error}
            onChange={e => setMaterial({ ...material, name: e.target.value })} />
          <TextField
            fullWidth
            label="Thickness"
            select
            value={material?.thickness ?? ""}
            error={errors?.thickness_has_error}
            helperText={errors?.thickness_error}
            onChange={e => setMaterial({ ...material, thickness: e.target.value })}
          >
            <MenuItem key="" value="">
            </MenuItem>
            <MenuItem key={CM12} value={CM12}>
              {getMaterialHeightDisplay(CM12)}
            </MenuItem>
            <MenuItem key={CM2} value={CM2}>
              {getMaterialHeightDisplay(CM2)}
            </MenuItem>
            <MenuItem key={CM3} value={CM3}>
              {getMaterialHeightDisplay(CM3)}
            </MenuItem>
            <MenuItem key={CM1} value={CM1}>
              {getMaterialHeightDisplay(CM1)}
            </MenuItem>
            <MenuItem key={CM60} value={CM60}>
              {getMaterialHeightDisplay(CM60)}
            </MenuItem>
            <MenuItem key={CM08} value={CM08}>
              {getMaterialHeightDisplay(CM08)}
            </MenuItem>
          </TextField>
          <FormControl sx={{ paddingLeft: "5px", marginTop: "10px" }}>
            <FormLabel id="add-customer-radios">
              <Typography variant="body2">
                Taxable
              </Typography>
            </FormLabel>
            <RadioGroup
              row
              value={material?.taxable ? "true" : "false"}
              aria-labelledby="taxable-radios"
              name="taxable-radios"
              onChange={e => setMaterial({ ...material, taxable: e.target.value === "true" ? true : false })}
            >
              <FormControlLabel
                value={"true"}
                control={<StyledRadio />}
                label={
                  <Typography variant="body2">
                    Yes
                  </Typography>
                } />
              <FormControlLabel
                value={"false"}
                control={<StyledRadio />}
                label={
                  <Typography variant="body2">
                    No
                  </Typography>
                } />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <BaseButton text="Cancel" icon={faTimes} clickEvent={() => setOpen(false)} />
        <BaseButton text={button_text} icon={faPlus} clickEvent={save} />
      </DialogActions>
    </Dialog>
  )
}
