/// RS ///
import Switch from "react-switch";
/// COLORS ///
import { grey_color } from "styles/style";
import { primary_color } from "styles/style";

interface Props {
  checked: boolean,
  onChange: (checked: boolean) => void
}

export default function PriceControlSwitch({ checked, onChange }: Props) {
  return (
    <Switch
      offColor={grey_color}
      onColor={primary_color}
      height={20}
      onChange={onChange}
      checked={checked} />
  )
}
