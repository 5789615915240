/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopInstallationCharge } from "api/actions/price_list";
/// TYPES ///
import { IInstallationCharge } from "api/types/price_list/installation_charge";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  installation_charge: IInstallationCharge
}

export default function InstallationCharge({ index, last, installation_charge }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={installation_charge?.price ?? 0}
      title={getMaterialHeightDisplay(installation_charge?.material_thickness ?? "")}
      end_text="$/SQFT"
      update={price => dispatch(updateShopInstallationCharge({ price: price }, installation_charge.id))} />
  )
}
