/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// TYPES ///
import { IPriceControl } from "api/types/price_list/price_control";
/// ACTIONS ///
import { getPriceList } from "api/actions/price_list";
import { getPriceControls } from "api/actions/price_list";
import { updatePriceControls } from "api/actions/price_list";
import { getShopApplianceEdges } from "api/actions/price_list";
import { getShopFinishedEdges } from "api/actions/price_list";
import { getShopMiters } from "api/actions/price_list";
import { getShopSplashes } from "api/actions/price_list";
import { getShopWaterfalls } from "api/actions/price_list";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import ApplianceEdge from "./appliance";
import FinishedEdge from "./finished";
import Miter from "./miter";
import Splash from "./splash";
import Waterfall from "./waterfall";
import Loading from "components/universal/loading";
import PageTitle from "components/universal/page/title";
import PriceControlSwitch from "components/price_lists/price_control/switch";
/// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { title_style } from "styles/quote_preview/section";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { list } from "values/breadcrumbs/market/pl";
import { overview } from "values/breadcrumbs/market/pl";

export default function EdgePriceList() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { price_list } = useAppSelector(state => state.price_list);
  const { appliance_edges } = useAppSelector(state => state.price_list);
  const { finished_edges } = useAppSelector(state => state.price_list);
  const { miters } = useAppSelector(state => state.price_list);
  const { splashes } = useAppSelector(state => state.price_list);
  const { waterfalls } = useAppSelector(state => state.price_list);
  const { price_controls } = useAppSelector(state => state.price_list);
  const { updating } = useAppSelector(state => state.price_list);
  const [update_price_control, setUpdatePriceControl] = useState<IPriceControl>({});

  useEffect(() => {
    setUpdatePriceControl(price_controls);
  }, [price_controls?.id]);

  useEffect(() => {
    if (!(price_list?.id) || price_list.id.toString() !== id) {
      dispatch(getPriceList(id));
    }
  }, [dispatch, id, price_list?.id]);

  useEffect(() => {
    if (id) {
      dispatch(getPriceControls());
      dispatch(getShopApplianceEdges(id));
      dispatch(getShopFinishedEdges(id));
      dispatch(getShopMiters(id));
      dispatch(getShopSplashes(id));
      dispatch(getShopWaterfalls(id));
    }
    dispatch(setBreadcrumbs([list, { ...overview, url: overview.url.replace("id", id) }]));
  }, [id, dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <PageTitle title={`${price_list?.name} Edges` ?? ""} endProps={<div></div>} />
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Appliance Edges
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_appliance_edge}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_appliance_edge: checked });
                  dispatch(updatePriceControls({ use_appliance_edge: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_appliance_edge ?
              appliance_edges.map((appliance_edge, index) => <ApplianceEdge key={index} index={index} appliance_edge={appliance_edge} last={index === appliance_edges.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Finished Edges
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_finished_edge}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_finished_edge: checked });
                  dispatch(updatePriceControls({ use_finished_edge: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_finished_edge ?
              finished_edges.map((finished_edge, index) => <FinishedEdge key={index} index={index} finished_edge={finished_edge} last={index === finished_edges.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Miters
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_miter}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_miter: checked });
                  dispatch(updatePriceControls({ use_miter: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_miter ?
              miters.map((miter, index) => <Miter key={index} index={index} miter={miter} last={index === miters.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Splashes
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_splash}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_splash: checked });
                  dispatch(updatePriceControls({ use_splash: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_splash ?
              splashes.map((splash, index) => <Splash key={index} index={index} splash={splash} last={index === splashes.length - 1} />) : null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Waterfalls
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_waterfall}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_waterfall: checked });
                  dispatch(updatePriceControls({ use_waterfall: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            update_price_control?.use_waterfall ?
              waterfalls.map((waterfall, index) => <Waterfall key={index} index={index} waterfall={waterfall} last={index === waterfalls.length - 1} />) : null
          }
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <Loading loading={updating} />
    </motion.div >
  )
}
