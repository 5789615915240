import { ICooktopCutout } from "api/types/price_list/cutout/cooktop";

export enum ActionType {
  GET_COOKTOP_CUTOUT_PRICES = "GET_COOKTOP_CUTOUT_PRICES",
  UPDATE_COOKTOP_CUTOUT_PRICE = "UPDATE_COOKTOP_CUTOUT_PRICE"
}

interface IGetCooktopCutouts {
  type: ActionType.GET_COOKTOP_CUTOUT_PRICES
  cooktop_cutouts: ICooktopCutout[]
}

interface IUpdateCooktopCutout {
  type: ActionType.UPDATE_COOKTOP_CUTOUT_PRICE,
  cooktop_cutout: ICooktopCutout
}

export type Action =
  IGetCooktopCutouts |
  IUpdateCooktopCutout;
