import type { Action as PriceListAction } from "./price_list";
import type { Action as InstallationChargeAction } from "./installation_charge";
import type { Action as FabricationChargeAction } from "./fabrication_charge";
import type { Action as TemplatingChargeAction } from "./templating_charge";
import type { Action as ApplianceEdgeAction } from "./edge/appliance";
import type { Action as FinishedEdgeAction } from "./edge/finished";
import type { Action as MiterAction } from "./edge/miter";
import type { Action as SplashAction } from "./edge/splash";
import type { Action as WaterfallAction } from "./edge/waterfall";
import type { Action as CooktopCutoutAction } from "./cutout/cooktop_cutout";
import type { Action as SinkCutoutAction } from "./cutout/sink_cutout";
import type { Action as OutletCutoutAction } from "./cutout/outlet_cutout";
import type { Action as FaucetCutoutAction } from "./cutout/faucet_cutout";
import type { Action as LaminateCountertopRemovalAction } from "./countertop_removal";
import type { Action as StoneCountertopRemovalAction } from "./countertop_removal";
import type { Action as BumpedOutCornerAction } from "./corner/bumped_out";
import type { Action as ClippedCornerAction } from "./corner/clipped";
import type { Action as InsideDiagonalAction } from "./corner/inside_diagonal";
import type { Action as InsideRadiusCornerAction } from "./corner/inside_radius";
import type { Action as NotchedCornerAction } from "./corner/notched";
import type { Action as OutsideRadiusCornerAction } from "./corner/outside_radius";
import type { Action as RecessedDiagonalCornerAction } from "./corner/recessed_diagonal";
import type { Action as BumpInAction } from "./bump/bump_in";
import type { Action as BumpInArcAction } from "./bump/bump_in_arc";
import type { Action as BumpOutAction } from "./bump/bump_out";
import type { Action as BumpOutArcAction } from "./bump/bump_out_arc";
import type { Action as FullArcAction } from "./bump/full_arc";
import type { Action as PriceControlAction } from "./price_control";
import type { Action as ErrorAction } from "./error";
import type { Action as LoadingAction } from "./loading";

import { ActionType as PriceListActionType } from "./price_list";
import { ActionType as InstallationChargeActionType } from "./installation_charge";
import { ActionType as FabricationChargeActionType } from "./fabrication_charge";
import { ActionType as TemplatingChargeActionType } from "./templating_charge";
import { ActionType as ApplianceEdgeActionType } from "./edge/appliance";
import { ActionType as FinishedEdgeActionType } from "./edge/finished";
import { ActionType as MiterActionType } from "./edge/miter";
import { ActionType as SplashActionType } from "./edge/splash";
import { ActionType as WaterfallActionType } from "./edge/waterfall";
import { ActionType as CooktopCutoutActionType } from "./cutout/cooktop_cutout";
import { ActionType as SinkCutoutActionType } from "./cutout/sink_cutout";
import { ActionType as OutletCutoutActionType } from "./cutout/outlet_cutout";
import { ActionType as FaucetCutoutActionType } from "./cutout/faucet_cutout";
import { ActionType as LaminateCountertopRemovalActionType } from "./countertop_removal";
import { ActionType as StoneCountertopRemovalActionType } from "./countertop_removal";
import { ActionType as BumpedOutCornerActionType } from "./corner/bumped_out";
import { ActionType as ClippedCornerActionType } from "./corner/clipped";
import { ActionType as InsideDiagonalActionType } from "./corner/inside_diagonal";
import { ActionType as InsideRadiusCornerActionType } from "./corner/inside_radius";
import { ActionType as NotchedCornerActionType } from "./corner/notched";
import { ActionType as OutsideRadiusCornerActionType } from "./corner/outside_radius";
import { ActionType as RecessedDiagonalCornerActionType } from "./corner/recessed_diagonal";
import { ActionType as BumpInActionType } from "./bump/bump_in";
import { ActionType as BumpInArcActionType } from "./bump/bump_in_arc";
import { ActionType as BumpOutActionType } from "./bump/bump_out";
import { ActionType as BumpOutArcActionType } from "./bump/bump_out_arc";
import { ActionType as FullArcActionType } from "./bump/full_arc";
import { ActionType as PriceControlActionType } from "./price_control";
import { ActionType as ErrorActionType } from "./error";
import { ActionType as LoadingActionType } from "./loading";

export const ActionType = {
  ...PriceListActionType,
  ...InstallationChargeActionType,
  ...FabricationChargeActionType,
  ...TemplatingChargeActionType,
  ...ApplianceEdgeActionType,
  ...FinishedEdgeActionType,
  ...MiterActionType,
  ...SplashActionType,
  ...WaterfallActionType,
  ...CooktopCutoutActionType,
  ...SinkCutoutActionType,
  ...OutletCutoutActionType,
  ...FaucetCutoutActionType,
  ...LaminateCountertopRemovalActionType,
  ...StoneCountertopRemovalActionType,
  ...BumpedOutCornerActionType,
  ...ClippedCornerActionType,
  ...InsideDiagonalActionType,
  ...InsideRadiusCornerActionType,
  ...NotchedCornerActionType,
  ...OutsideRadiusCornerActionType,
  ...RecessedDiagonalCornerActionType,
  ...BumpInActionType,
  ...BumpInArcActionType,
  ...BumpOutActionType,
  ...BumpOutArcActionType,
  ...FullArcActionType,
  ...PriceControlActionType,
  ...ErrorActionType,
  ...LoadingActionType
}

export type Action =
  PriceListAction |
  InstallationChargeAction |
  FabricationChargeAction |
  TemplatingChargeAction |
  ApplianceEdgeAction |
  FinishedEdgeAction |
  MiterAction |
  SplashAction |
  WaterfallAction |
  CooktopCutoutAction |
  SinkCutoutAction |
  OutletCutoutAction |
  FaucetCutoutAction |
  LaminateCountertopRemovalAction |
  StoneCountertopRemovalAction |
  BumpedOutCornerAction |
  ClippedCornerAction |
  InsideDiagonalAction |
  InsideRadiusCornerAction |
  NotchedCornerAction |
  OutsideRadiusCornerAction |
  RecessedDiagonalCornerAction |
  BumpInAction |
  BumpInArcAction |
  BumpOutAction |
  BumpOutArcAction |
  FullArcAction |
  PriceControlAction |
  ErrorAction |
  LoadingAction;

