import { IBumpOutArc } from "api/types/price_list/bump/out_arc";

export enum ActionType {
  GET_BUMP_OUT_ARC_PRICES = "GET_BUMP_OUT_ARC_PRICES",
  UPDATE_BUMP_OUT_ARC_PRICE = "UPDATE_BUMP_OUT_ARC_PRICE"
}

interface IGetBumpOutArcs {
  type: ActionType.GET_BUMP_OUT_ARC_PRICES
  bump_out_arcs: IBumpOutArc[]
}

interface IUpdateBumpOutArc {
  type: ActionType.UPDATE_BUMP_OUT_ARC_PRICE,
  bump_out_arc: IBumpOutArc
}

export type Action = IGetBumpOutArcs | IUpdateBumpOutArc;
