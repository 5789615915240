import { IBumpedOutCorner } from "api/types/price_list/corner/bumped_out";

export enum ActionType {
  GET_BUMPED_OUT_CORNER_PRICES = "GET_BUMPED_OUT_CORNER_PRICES",
  UPDATE_BUMPED_OUT_CORNER_PRICE = "UPDATE_BUMPED_OUT_CORNER_PRICE"
}

interface IGetIBumpedOutCorners {
  type: ActionType.GET_BUMPED_OUT_CORNER_PRICES
  bumped_out_corners: IBumpedOutCorner[]
}

interface IUpdateIBumpedOutCorner {
  type: ActionType.UPDATE_BUMPED_OUT_CORNER_PRICE,
  bumped_out_corner: IBumpedOutCorner
}

export type Action = IGetIBumpedOutCorners | IUpdateIBumpedOutCorner;
