/// HOOKS ///
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
/// ROUTER ///
import { Location } from "react-router-dom";
/// MUI COMPONENTS ///
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { List } from "@mui/material";
import { ListItemButton } from "@mui/material";
/// FA ///
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/// ICONS ///
import { faGrip } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faSheetPlastic } from "@fortawesome/free-solid-svg-icons";
import { faShop } from "@fortawesome/free-solid-svg-icons";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
/// URLS ///
import { end_customer_url } from "components/navigation/endpoints";
import { event_url } from "components/navigation/endpoints";
import { home_url } from "components/navigation/endpoints";
import { material_url } from "components/navigation/endpoints";
import { order_url } from "components/navigation/endpoints";
import { org_url } from "components/navigation/endpoints";
import { price_list_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
import { shop_url } from "components/navigation/endpoints";
import { shops_url } from "components/navigation/endpoints";
import { week_url } from "components/navigation/endpoints";
/// STYLES ///
import { mini_btn_style } from "styles/drawer";
import { mini_ico_style } from "styles/drawer";

export default function MiniShopDrawer() {
  const navigate = useNavigate();
  const location: Location<any> = useLocation();

  return (
    <List>
      <ListItemButton key="Dashboard" sx={mini_btn_style(location.pathname === home_url)} onClick={() => navigate(home_url)}>
        <Tooltip title="Dashboard" placement="right">
          <FontAwesomeIcon icon={faGrip} style={mini_ico_style(location.pathname === home_url)} />
        </Tooltip>
      </ListItemButton>
      <ListItemButton key="Cx" sx={mini_btn_style(location.pathname.includes(end_customer_url))} onClick={() => navigate(`/${end_customer_url}`)}>
        <Tooltip title="Accounts" placement="right">
          <FontAwesomeIcon icon={faUserGroup} style={mini_ico_style(location.pathname.includes(end_customer_url))} />
        </Tooltip>
      </ListItemButton>
      <ListItemButton key="Quote" sx={mini_btn_style(location.pathname.includes(quote_url))} onClick={() => navigate(`/${shop_url}/${quote_url}`)}>
        <Tooltip title="Quote" placement="right">
          <FontAwesomeIcon icon={faFileInvoiceDollar} style={mini_ico_style(location.pathname.includes(quote_url))} />
        </Tooltip>
      </ListItemButton>
      <ListItemButton key="PO" sx={mini_btn_style(location.pathname.includes(order_url))} onClick={() => navigate(`/${shop_url}/${order_url}`)}>
        <Tooltip title="POs" placement="right">
          <FontAwesomeIcon icon={faCartShopping} style={mini_ico_style(location.pathname.includes(order_url))} />
        </Tooltip>
      </ListItemButton>
      <ListItemButton key="Calendar" sx={mini_btn_style(location.pathname.includes(event_url))} onClick={() => navigate(`/${event_url}/${week_url}`)}>
        <Tooltip title="Calendar" placement="right">
          <FontAwesomeIcon icon={faCalendar} style={mini_ico_style(location.pathname.includes(event_url))} />
        </Tooltip>
      </ListItemButton>
      <Divider sx={{ marginBottom: "2px", marginTop: "2px" }} />
      <ListItemButton key="FabPl" sx={mini_btn_style(location.pathname.includes(price_list_url))} onClick={() => navigate(`/${price_list_url}`)}>
        <Tooltip title="Price Lists" placement="right">
          <FontAwesomeIcon icon={faList} style={mini_ico_style(location.pathname.includes(price_list_url))} />
        </Tooltip>
      </ListItemButton>
      <ListItemButton key="Material" sx={mini_btn_style(location.pathname.includes(material_url))} onClick={() => navigate(`/${shop_url}/${material_url}`)}>
        <Tooltip title="Material" placement="right">
          <FontAwesomeIcon icon={faSheetPlastic} style={mini_ico_style(location.pathname.includes(material_url))} />
        </Tooltip>
      </ListItemButton>
      <Divider sx={{ marginBottom: "2px", marginTop: "2px" }} />
      <ListItemButton key="Org" sx={mini_btn_style(location.pathname.includes(org_url))} onClick={() => navigate(`/${org_url}`)}>
        <Tooltip title="Organization" placement="right">
          <FontAwesomeIcon icon={faBuilding} style={mini_ico_style(location.pathname.includes(org_url))} />
        </Tooltip>
      </ListItemButton>
      <ListItemButton key="Shops" sx={mini_btn_style(location.pathname.includes(shops_url))} onClick={() => navigate(`/${shops_url}`)}>
        <Tooltip title="Shops" placement="right">
          <FontAwesomeIcon icon={faShop} style={mini_ico_style(location.pathname.includes(shops_url))} />
        </Tooltip>
      </ListItemButton>
    </List>
  );
}
