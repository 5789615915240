/// REACT ///
import { useState } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { deleteShopColor } from "api/actions/shop_material";
import { updateShopColor } from "api/actions/shop_material";
/// COMPONENTS ///
import GridSaveButton from "./save";
/// MUI ///
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
/// MUIX ///
import { GridRowModesModel } from "@mui/x-data-grid";
import { GridColumnHeaderParams } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { GridEditRowProps } from "@mui/x-data-grid";
import { GridEventListener } from "@mui/x-data-grid";
import { GridEditInputCell } from "@mui/x-data-grid";
import { GridCell } from "@mui/x-data-grid";
import { GridPreProcessEditCellProps } from "@mui/x-data-grid";
import { GridRowId } from "@mui/x-data-grid";
import { GridRowModel } from "@mui/x-data-grid";
import { GridRowModes } from "@mui/x-data-grid";
import { GridRowEditStopReasons } from "@mui/x-data-grid";
/// ICONS ///
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CancelIcon from "@mui/icons-material/Close";
/// COLORS ///
import { error_color } from "styles/style";
import { white_color } from "styles/style";

export default function FullFeaturedCrudGrid() {
  const dispatch = useAppDispatch();
  const [toast_open, setToastOpen] = useState<boolean>(false);
  const [toast_message, setToastMessage] = useState<string>("");
  const { colors } = useAppSelector(state => state.shop_material);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const save = (id: GridRowId, state: GridEditRowProps) => {
    let error: boolean = false;
    let error_message: string = "";

    if (state.name.error) {
      error = true;
      error_message += "Name cannot be blank.\n";
    }

    if (state.price.error) {
      error = true;
      error_message += "Price cannot be blank or negative.\n"
    }

    if (state.width.error) {
      error = true;
      error_message += "Width cannot be blank or negative.\n";
    }

    if (state.length.error) {
      error = true;
      error_message += "Length cannot be blank or negative.\n";
    }

    if (error) {
      setToastOpen(true);
      setToastMessage("Save Error:\n" + error_message);
    }
    else {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    dispatch(deleteShopColor(Number(id)));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = (new_row: GridRowModel) => {
    dispatch(updateShopColor(
      {
        ...new_row,
        price: Math.round(new_row.price * 100) / 100,
        width: Math.round(new_row.width * 100) / 100,
        length: Math.round(new_row.length * 100) / 100,
        amount: Math.round(new_row.amount)
      },
      new_row.id
    ));
    return new_row;
  };

  const processRowUpdateError = () => {

  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    //setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {"Name"}
        </strong>
      ),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const has_error = !params.props.value || params.props.value === "";
        return { ...params.props, error: has_error };
      },
      flex: 2,
      editable: true,
      renderEditCell: params => {
        return <GridEditInputCell {...params} disabled={params.row.required} />
      }
    },
    {
      field: "price",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {"Price/SQFT ($)"}
        </strong>
      ),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const has_error: boolean = !params.props.value || isNaN(Number(params.props.value)) || Number(params.props.value) < 0;
        return { ...params.props, error: has_error };
      },
      valueFormatter: (value?: number) => {
        if (value == null) {
          return '';
        }
        return `${value.toFixed(2)}`;
      },
      type: "number",
      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "width",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {"Width (\") "}
        </strong>
      ),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const has_error: boolean = !params.props.value || isNaN(Number(params.props.value)) || Number(params.props.value) < 0;
        return { ...params.props, error: has_error };
      },
      valueFormatter: (value?: number) => {
        if (value == null) {
          return '';
        }
        return `${value.toFixed(2)}`;
      },
      headerName: "Width",
      type: "number",
      flex: 1,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderEditCell: params => {
        return <GridEditInputCell {...params} disabled={params.row.required} />
      }
    },
    {
      field: "length",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {"Length (\") "}
        </strong>
      ),
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const has_error: boolean = !params.props.value || isNaN(Number(params.props.value)) || Number(params.props.value) < 0;
        return { ...params.props, error: has_error };
      },
      valueFormatter: (value?: number) => {
        if (value == null) {
          return '';
        }
        return `${value.toFixed(2)}`;
      },
      flex: 1,
      editable: true,
      align: "left",
      headerAlign: "left",
      type: "number",
      renderEditCell: params => {
        return <GridEditInputCell {...params} disabled={params.row.required} />
      }
    },
    {
      field: "amount",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {"Amount"}
        </strong>
      ),
      valueFormatter: (value?: number) => {
        if (value == null) {
          return '';
        }
        return `${value.toFixed(0)}`;
      },
      flex: 1,
      editable: true,
      align: "left",
      headerAlign: "left",
      type: "number",
      renderEditCell: params => {
        return <GridEditInputCell {...params} disabled={params.row.required} />
      }
    },
    {
      field: "required",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {"Standard"}
        </strong>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      type: "boolean"
    },

    {
      field: "actions",
      type: "actions",
      align: "right",
      headerAlign: "right",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {"Actions"}
        </strong>
      ),
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridSaveButton
              id={id}
              handleSaveClick={(id, state) => save(id, state)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={colors}
        columns={columns}
        autoHeight
        density="compact"
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={processRowUpdateError}
        slotProps={{
          toolbar: { setRowModesModel },
        }}
        sx={{
          '& .Mui-error': {
            backgroundColor: 'rgb(126,10,15, 0.1)',
            color: error_color,
            paddingBottom: "50px"
          },
        }}
      />
      <Snackbar
        open={toast_open}
        sx={{ marginLeft: "80px", marginBottom: "50px" }}
        autoHideDuration={9000}
        onClose={() => setToastOpen(false)}
        message={toast_message}>
        <Alert
          icon={false}
          sx={{ backgroundColor: error_color, color: white_color }}
        >
          {toast_message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
