import { IInstallationCharge } from "api/types/price_list/installation_charge";

export enum ActionType {
  GET_INSTALLATION_CHARGE_PRICES = "GET_INSTALLATION_CHARGE_PRICES",
  UPDATE_INSTALLATION_CHARGE_PRICE = "UPDATE_INSTALLATION_CHARGE_PRICE"
}

interface IGetInstallationCharges {
  type: ActionType.GET_INSTALLATION_CHARGE_PRICES
  installation_charges: IInstallationCharge[]
}

interface IUpdateInstallationCharge {
  type: ActionType.UPDATE_INSTALLATION_CHARGE_PRICE,
  installation_charge: IInstallationCharge
}

export type Action =
  IGetInstallationCharges |
  IUpdateInstallationCharge;
