import { IOutsideRadiusCorner } from "api/types/price_list/corner/outside_radius";

export enum ActionType {
  GET_OUTSIDE_RADIUS_CORNER_PRICES = "GET_OUTSIDE_RADIUS_CORNER_PRICES",
  UPDATE_OUTSIDE_RADIUS_CORNER_PRICE = "UPDATE_OUTSIDE_RADIUS_CORNER_PRICE",
}

interface IGetOutsideRadiusCorners {
  type: ActionType.GET_OUTSIDE_RADIUS_CORNER_PRICES
  outside_radius_corners: IOutsideRadiusCorner[]
}

interface IUpdateOutsideRadiusCorner {
  type: ActionType.UPDATE_OUTSIDE_RADIUS_CORNER_PRICE,
  outside_radius_corner: IOutsideRadiusCorner
}

export type Action = IGetOutsideRadiusCorners | IUpdateOutsideRadiusCorner;
