/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopWaterfall } from "api/actions/price_list";
/// TYPES ///
import { IWaterfall } from "api/types/price_list/edge/waterfall";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  waterfall: IWaterfall
}

export default function Waterfall({ index, last, waterfall }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={waterfall?.price ?? 0}
      title={getMaterialHeightDisplay(waterfall?.material_thickness ?? "")}
      end_text="$/LIN"
      update={price => dispatch(updateShopWaterfall({ price: price }, waterfall.id))} />
  )
}
