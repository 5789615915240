import { IFaucetCutout } from "api/types/price_list/cutout/faucet";

export enum ActionType {
  GET_FAUCET_CUTOUT_PRICES = "GET_FAUCET_CUTOUT_PRICES",
  UPDATE_FAUCET_CUTOUT_PRICE = "UPDATE_FAUCET_CUTOUT_PRICE"
}

interface IGetFaucetCutouts {
  type: ActionType.GET_FAUCET_CUTOUT_PRICES
  faucet_cutouts: IFaucetCutout[]
}

interface IUpdateFaucetCutout {
  type: ActionType.UPDATE_FAUCET_CUTOUT_PRICE,
  faucet_cutout: IFaucetCutout
}

export type Action =
  IGetFaucetCutouts |
  IUpdateFaucetCutout;
