/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getMarketOrder } from "api/actions/order/market_order";
import { getOrderCounters } from "api/actions/order/counter";
import { getOrderAreas } from "api/actions/order/area";
import { getOrderSlabs } from "api/actions/order/slab";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
/// COMPONENTS ///
import LoadingPage from "components/universal/loading_page";
import OrderPrintAreas from "components/universal/diagrams/print_order_counter/areas";
import OrderPrintShapeAreas from "components/universal/diagrams/print_order_cut_shapes/areas";
import POPrintHeader from "components/universal/print/header/po";
import PrintAddons from "components/universal/print/addons";
import PrintLineItems from "components/universal/print/line_items";
import PrintSignature from "components/universal/print/signature";
import PrintSummaryHeaders from "components/universal/print/summary_headers";
import PrintSummary from "components/universal/print/summary";
import PrintTerms from "components/universal/print/terms";
/// PDF ///
import { PDFViewer } from "@react-pdf/renderer";
import { Page } from "@react-pdf/renderer";
import { Document } from "@react-pdf/renderer";
import { StyleSheet } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import { View } from "@react-pdf/renderer";
import { Image } from "@react-pdf/renderer";
/// IMAGE ///
import PowerByLogo from "static/powered_by_logo.png";
/// FM ///
import { motion } from "framer-motion";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
/// COLORS ///
import { primary_color } from "styles/style";

export default function StorePOPrint() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [logo, setLogo] = useState<string | null>(null);
  const [logo_read, setLogoRead] = useState<boolean>(false);
  const {
    areas,
    areas_loaded,
    counters,
    counters_loaded,
    order,
    slabs,
    slabs_loaded
  } = useAppSelector((state) => state.order);

  useEffect(() => {
    if (id) {
      dispatch(getMarketOrder(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(getOrderAreas(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(getOrderCounters(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(getOrderSlabs(id));
    }
  }, [dispatch, id]);

  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
      },
      {
        src: "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
        fontWeight: "bold"
      }
    ]
  });

  useEffect(() => {
    if (order?.fabricator_data?.logo && typeof (order?.fabricator_data?.logo) === "string") {
      fetch(order?.fabricator_data?.logo).then(response => {
        return response.blob();
      }).then(blob => {
        const blob_url: string = URL.createObjectURL(blob);
        setLogo(blob_url);
        setLogoRead(true);
      }).catch(() => {
        setLogoRead(true);
      })
    }
    else {
      setLogoRead(true);
    }
  }, [order?.vendor_data?.logo]);

  const styles = StyleSheet.create({
    page: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "40px",
      paddingBottom: "40px"
    },
    title: {
      fontWeight: "bold",
      fontSize: 14
    },
    body2: {
      fontSize: 14
    },
    logo: {
      height: "40px",
      width: "80px",
      objectFit: "contain",
      objectPosition: "left"
    },
    row: {
      marginTop: "25px",
      display: "flex",
      flexDirection: "row"
    },
    font: {
      fontFamily: "Montserrat"
    },
    footer: {
      backgroundColor: primary_color,
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
      height: "25px",
      paddingBottom: "2px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    footer_image: {
      height: "20px",
      width: "100px"
    }
  });

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={!order?.id || !logo_read || !counters_loaded || !areas_loaded || !slabs_loaded}>
        <Box display="flex" justifyContent="center">
          <PDFViewer width="795px" height="1123px">
            <Document>
              <Page size="A4" style={styles.page}>
                <POPrintHeader
                  title="PURCHASE ORDER"
                  number={order?.order_number ?? ""}
                  logo_url={logo ? logo : ""}
                  vendor_name={order?.fabricator_data?.business_name ?? ""}
                  vendor_address={order?.fabricator_data?.address ?? ""}
                  vendor_city={order?.fabricator_data?.city ?? ""}
                  vendor_province={order?.fabricator_data?.province ?? ""}
                  vendor_postal_code={order?.fabricator_data?.postal_code ?? ""}
                  vendor_phone_number={order?.fabricator_data?.phone_number ?? ""}
                  vendor_email={order?.fabricator_data?.email ?? ""}
                  bill_to_name={order?.vendor_data?.business_name ?? ""}
                  bill_to_address={order?.vendor_data?.address ?? ""}
                  bill_to_city={order?.vendor_data?.city ?? ""}
                  bill_to_province={order?.vendor_data?.province ?? ""}
                  bill_to_postal_code={order?.vendor_data?.postal_code ?? ""}
                  bill_to_phone_number={order?.vendor_data?.phone_number ?? ""}
                  bill_to_email={order?.vendor_data?.email ?? ""}
                  job_site_address={order?.job_data?.geo_address ?? ""}
                  job_site_city={""}
                  job_site_province={""}
                  job_site_postal_code={""}
                  job_site_phone_number={order?.job_data?.phone_number ?? ""}
                  job_site_email={order?.job_data?.email ?? ""}
                  date={order?.created ?? ""} />
                <PrintSummaryHeaders />
                <PrintLineItems areas={areas} />
                {order?.addons?.length > 0 ? <PrintAddons addons={order?.addons ?? []} /> : null}
                <PrintSummary
                  total={order?.total ?? 0}
                  tax_name={order?.tax_name ?? "Tax"}
                  tax={order?.tax ?? 0}
                  second_tax_name={order?.second_tax_name ?? "Tax2"}
                  second_tax={order?.second_tax ?? 0}
                  discount={order?.discount ?? 0} />
                <OrderPrintAreas counters={counters} areas={areas} />
                <OrderPrintShapeAreas slabs={slabs} areas={areas} />
                <PrintTerms payment_terms={order?.fabricator_data?.payment_terms ?? ""} title="Fabricator Terms & Conditions" />
                <PrintSignature
                  signature={order?.signature_image ?? ""}
                  name={`${order?.end_customer_data?.first_name ?? ""} ${order?.end_customer_data?.last_name ?? ""}`}
                  date={order?.signature_date ?? ""} />
                <View style={styles.footer} fixed>
                  <Image src={PowerByLogo} style={styles.footer_image} />
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </Box>
      </LoadingPage>
    </motion.div>
  );
}
