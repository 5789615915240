/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { setBreadcrumbs } from "api/actions/ui/set";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import Loading from "components/universal/loading";
import PageTitle from "components/universal/page/title";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
/// ICONS ///
import { faEdit } from "@fortawesome/free-solid-svg-icons";
/// FM ///
import { motion } from "framer-motion";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STLYES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { title_style } from "styles/quote_preview/section";
import { item_style } from "styles/quote_preview/section";
import { multiline_item_style } from "styles/quote_preview/section";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { shops_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { shops_list } from "values/breadcrumbs/shop";
import { shop } from "values/breadcrumbs/shop";

export default function Shop() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { updating, user } = useAppSelector((state) => state.authentication);

  useEffect(() => {
    dispatch(setBreadcrumbs([shops_list, { ...shop, url: shop.url.replace("id", id) }]));
  }, [dispatch, id]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start">
        <Grid container item xs={12} lg={6}>
          <Grid item xs={12}>
            <PageTitle title="Shop" endProps={
              <BaseButton text="Edit" icon={faEdit} clickEvent={() => navigate(`/${shops_url}/${detail_url}/${user.id}/update`)} />
            } />
          </Grid>
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={title_style}>
              Service Location
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Region</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {user?.region_data?.name ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Service Location</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {user?.geo_address ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Coverage Radius</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {user?.radius ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Travel Rate One Way (KM)</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              ${user?.mileage_rate ?? ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Typography variant="h6" sx={title_style}>
              Service Details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Shop Markup Rate</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {user?.shop_rate ?? 0}%
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Market Markup Rate</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {user?.market_rate ?? 0}%
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Template Timeline</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {user?.template_timeline ?? ""} days
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Installation Timeline</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {user?.installation_timeline ?? ""} days
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Warranty Days</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {user?.warranty_days ?? ""} days
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography variant="body2" sx={item_style(false)}>
              <b>Payment Terms</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={multiline_item_style}>
              {user?.payment_terms ?? ""}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Loading loading={updating} />
    </motion.div>
  )
}
