/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopClippedCorner } from "api/actions/price_list";
/// TYPES ///
import { IClippedCorner } from "api/types/price_list/corner/clipped";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  clipped_corner: IClippedCorner
}

export default function ClippedCorner({ index, last, clipped_corner }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={clipped_corner?.price ?? 0}
      title={getMaterialHeightDisplay(clipped_corner?.material_thickness ?? "")}
      end_text="$"
      end_padding="45px"
      update={price => dispatch(updateShopClippedCorner({ price: price }, clipped_corner.id))} />
  )
}
