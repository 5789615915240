/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { updateProfile } from "api/actions/authentication";
import { setBreadcrumbs } from "api/actions/ui/set";
/// TYPES ///
import { IShopErrors } from "api/types/shop";
import { User } from "api/types/authentication";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Fab } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
/// PAGE COMPONENTS ///
import BaseButton from "components/universal/button/base";
import Loading from "components/universal/loading";
import LoadingPage from "components/universal/loading_page";
import PageTitle from "components/universal/page/title";
/// COMPONENTS ///
import AddressAutocomplete from "components/universal/autocomplete/geo";
import Map from "components/universal/map/map";
/// FM ///
import { motion } from "framer-motion";
/// ICONS ///
import AddIcon from "@mui/icons-material/Add";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import RemoveIcon from "@mui/icons-material/Remove";
/// VALIDATE ///
import { checkGeo } from "values/check/shop";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { shops_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { shops_list } from "values/breadcrumbs/shop";
import { shop } from "values/breadcrumbs/shop";
import { shop_update } from "values/breadcrumbs/shop";

export default function UpdateShop() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { updating, user } = useAppSelector((state) => state.authentication);
  const [geo, setGeo] = useState<User>({});
  const [errors, setErrors] = useState<IShopErrors>({});
  const [zoom, setZoom] = useState<number>(8);

  useEffect(() => {
    dispatch(setBreadcrumbs([
      shops_list,
      { ...shop, url: shop.url.replace("id", id) },
      { ...shop_update, url: shop_update.url.replace("id", id) }
    ]));
  }, [dispatch, id]);

  const saveString = (key: string, value: string | number) => {
    setGeo({ ...geo, [key]: value });
    setErrors({ ...errors, [`${key}_error`]: "", [`${key}_has_error`]: false });
  }

  const updateGeo = (label: string, lat: number, lng: number, address: string, city: string, province: string) => {
    setGeo({
      ...geo,
      geo_address: label,
      lat: lat,
      lng: lng
    });
  }

  const geoError = () => {
    setErrors({ ...errors, geo_error: "Unable to retrieve coordinates of this address.", geo_has_error: true });
  }

  const update = () => {
    const errors: IShopErrors = checkGeo(geo);

    if (errors.geo_has_error || errors.radius_has_error || errors.mil_rate_has_error) {
      setErrors(errors);
      return;
    }

    dispatch(updateProfile(geo, user.id));
    navigate(`/${shops_url}/${detail_url}/${user.id}`);
  }

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={!(user?.id)}>
        <Grid container>
          <PageTitle title="Update Shop" size_lg={6} endProps={
            <Box>
              <BaseButton text="Cancel" icon={faTimes} clickEvent={() => navigate(`/${shops_url}/${detail_url}/${user.id}`)} />
              <BaseButton clickEvent={update} text="Update" icon={faSave} />
            </Box>
          } />
          <Grid item xs={0} lg={6}></Grid>
          <Grid item xs={12} lg={6}>
            <Box style={header_style}></Box>
          </Grid>
          <Grid item xs={0} lg={6}></Grid>
          <Grid item xs={12} lg={6} sx={{ paddingTop: "20px" }}>
            <Box display="flex" flexDirection="column" alignItems="flex-start" sx={{ gap: "10px" }}>
              <TextField
                fullWidth
                defaultValue={user?.payment_terms ?? ""}
                onChange={e => saveString("payment_terms", e.target.value)}
                size="small"
                label="Payment Terms"
                multiline />
              <Stack direction="row" spacing={2}>
                <TextField
                  defaultValue={user?.template_timeline ?? 0}
                  type="number"
                  onChange={e => saveString("template_timeline", Number(e.target.value))}
                  label="Template Timeline"
                  size="small" />
                <TextField
                  defaultValue={user?.installation_timeline ?? 0}
                  type="number"
                  onChange={e => saveString("installation_timeline", Number(e.target.value))}
                  label="Installation Timeline"
                  size="small" />
                <TextField
                  defaultValue={user?.warranty_days ?? 0}
                  type="number"
                  onChange={e => saveString("warranty_days", Number(e.target.value))}
                  label="Warranty Days"
                  size="small" />
              </Stack>
              <AddressAutocomplete
                input_label="Service Address"
                label={geo.geo_address ? geo.geo_address : user.geo_address}
                error={errors.geo_error}
                has_error={errors.geo_has_error}
                geoError={geoError}
                updateGeo={updateGeo} />
              <TextField
                defaultValue={user.radius}
                type="number"
                onChange={e => saveString("radius", Number(e.target.value))}
                error={errors.radius_has_error}
                helperText={errors.radius_error}
                label="Radius"
                size="small" />
              <TextField
                defaultValue={user.mileage_rate}
                onChange={e => saveString("mileage_rate", Number(e.target.value))}
                type="number"
                error={errors.mil_rate_has_error}
                helperText={errors.mil_rate_error}
                label="Travel Cost - 1 Way (KM)"
                size="small" />
            </Box>
            <Box sx={{ marginTop: "30px" }}>
              <Map
                lat={geo.lat ? geo.lat : user.lat}
                lng={geo.lng ? geo.lng : user.lng}
                radius={geo.radius ? geo.radius : user.radius}
                isStatic={true}
                zoom={zoom} />
              <Box sx={{ position: "relative", bottom: 50, right: 5 }}>
                <Box display="flex" justifyContent="right">
                  <Fab sx={{ marginRight: 1 }} size="small" color="primary" aria-label="zoom-out" onClick={() => setZoom(prev => prev - 1)}>
                    <RemoveIcon />
                  </Fab>
                  <Fab size="small" color="primary" aria-label="zoom-in" onClick={() => setZoom(prev => prev + 1)}>
                    <AddIcon />
                  </Fab>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </LoadingPage>
      <Loading loading={updating} />
    </motion.div>
  )
}
