/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// TYPES ///
import { IPriceControl } from "api/types/price_list/price_control";
/// ACTIONS ///
import { getPriceControls } from "api/actions/price_list";
import { updatePriceControls } from "api/actions/price_list";
import { updateProfile } from "api/actions/authentication";
import { setBreadcrumbs } from "api/actions/ui/set";
/// MUI ///
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// COMPONENTS ///
import Loading from "components/universal/loading";
import PageTitle from "components/universal/page/title";
import PriceControlSwitch from "components/price_lists/price_control/switch";
import PriceRow from "components/price_lists/price_list/row";
/// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { title_style } from "styles/quote_preview/section";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { list } from "values/breadcrumbs/market/pl";
import { overview } from "values/breadcrumbs/market/pl";

export default function UniversalPriceList() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { user } = useAppSelector(state => state.authentication);
  const { price_controls } = useAppSelector(state => state.price_list);
  const { updating } = useAppSelector(state => state.price_list);
  const [update_price_control, setUpdatePriceControl] = useState<IPriceControl>({});

  useEffect(() => {
    setUpdatePriceControl(price_controls);
  }, [price_controls?.id]);

  useEffect(() => {
    if (id) {
      dispatch(getPriceControls());
    }
    dispatch(setBreadcrumbs([list, { ...overview, url: overview.url.replace("id", id) }]));
  }, [id, dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container sx={list_container_style}>
        <PageTitle title="Universal Charges" endProps={<div></div>} />
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Mileage
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_mileage_cost}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_mileage_cost: checked });
                  dispatch(updatePriceControls({ use_mileage_cost: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            price_controls?.use_mileage_cost ?
              <PriceRow
                index={0}
                last={true}
                original_price={user?.laminate_counter_removal ?? 0}
                title="Mileage Charge"
                end_text="$/KM"
                end_padding="15px"
                update={price => dispatch(updateProfile({ mileage_rate: price }, user.id))} /> :
              null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Counter Removal
              </Typography>
              <PriceControlSwitch
                checked={update_price_control?.use_countertop_removal}
                onChange={checked => {
                  setUpdatePriceControl({ ...update_price_control, use_countertop_removal: checked });
                  dispatch(updatePriceControls({ use_countertop_removal: checked }, price_controls?.id));
                }} />
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
          {
            price_controls?.use_countertop_removal ?
              <Fragment>
                <PriceRow
                  index={0}
                  last={false}
                  original_price={user?.laminate_counter_removal ?? 0}
                  title="Laminate Removal Charge"
                  end_text="$/SQFT"
                  update={price => dispatch(updateProfile({ laminate_counter_removal: price }, user.id))} />
                <PriceRow
                  index={1}
                  last={true}
                  original_price={user?.stone_counter_removal ?? 0}
                  title="Stone Removal Charge"
                  end_text="$/SQFT"
                  update={price => dispatch(updateProfile({ stone_counter_removal: price }, user.id))} />
              </Fragment> :
              null
          }
          <Grid item xs={6} sx={{ marginTop: "10px", marginBottom: "15px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" sx={title_style}>
                Quote Markup
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
            <PriceRow
              index={0}
              last={false}
              original_price={user?.market_rate ?? 0}
              title="Market Markup"
              end_text="%"
              end_padding="45px"
              update={price => dispatch(updateProfile({ market_rate: price }, user.id))} />
            <PriceRow
              index={1}
              last={true}
              original_price={user?.shop_rate ?? 0}
              title="Shop Markup"
              end_text="%"
              end_padding="45px"
              update={price => dispatch(updateProfile({ shop_rate: price }, user.id))} />
        </Grid>
      </Grid>
      <Loading loading={updating} />
    </motion.div>
  )
}
