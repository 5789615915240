export enum ActionType {
  PRICE_LIST_LOADING = "PRICE_LIST_LOADING",
  PRICE_LIST_UPDATING = "PRICE_LIST_UPDATING"
}

interface IPriceListLoading {
  type: ActionType.PRICE_LIST_LOADING
}

interface IPriceListUpdating {
  type: ActionType.PRICE_LIST_UPDATING
}

export type Action = IPriceListLoading | IPriceListUpdating;
