export enum ActionType {
  PRICE_LIST_ERROR = "PRICE_LIST_ERROR"
}

interface IPriceListError {
  type: ActionType.PRICE_LIST_ERROR,
  payload: string
}

export type Action = IPriceListError;
