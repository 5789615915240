/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
/// ACTIONS ///
import { setBreadcrumbs } from "api/actions/ui/set";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import PageTitle from "components/universal/page/title";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
/// FM ///
import { motion } from "framer-motion";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
import { item_style } from "styles/quote_preview/section";
import { multiline_item_style } from "styles/quote_preview/section";
import { title_style } from "styles/quote_preview/section";
/// URLS ///
import { detail_url } from "components/navigation/endpoints";
import { stores_url } from "components/navigation/endpoints";
import { update_url } from "components/navigation/endpoints";
/// BREADCRUMBS ///
import { stores_list } from "values/breadcrumbs/store";
import { store } from "values/breadcrumbs/store";

export default function Store() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.authentication);

  useEffect(() => {
    dispatch(setBreadcrumbs([stores_list, store]));
  }, [dispatch]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <Grid container alignItems="flex-start" sx={list_container_style}>
        <Grid container item xs={12} lg={6}>
          <Grid item xs={12}>
            <PageTitle title="Store" endProps={
              <BaseButton text="Edit" icon={faEdit} clickEvent={() => navigate(`/${stores_url}/${detail_url}/${user.id}/${update_url}`)} />
            } />
          </Grid>
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={title_style}>
              Service Location
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Region</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {user.region_data?.name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Service Location</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {user.geo_address}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "20px" }}>
            <Typography variant="h6" sx={title_style}>
              Service Details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={item_style()}>
              <b>Markup Rate</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" align="right" sx={item_style()}>
              {user?.markup_rate ?? 0}%
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography variant="body2" sx={item_style(false)}>
              <b>Payment Terms</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={multiline_item_style}>
              {user?.payment_terms ?? ""}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </motion.div>
  )
}
