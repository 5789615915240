/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
/// ACTIONS ///
import { updateShopApplianceEdge } from "api/actions/price_list";
/// TYPES ///
import { IApplianceEdge } from "api/types/price_list/edge/appliance";
/// COMPONENTS ///
import PriceRow from "../../row";
/// VALUES ///
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  index: number,
  last: boolean,
  appliance_edge: IApplianceEdge
}

export default function ApplianceEdge({ index, last, appliance_edge }: Props) {
  const dispatch = useAppDispatch();

  return (
    <PriceRow
      index={index}
      last={last}
      original_price={appliance_edge?.price ?? 0}
      title={getMaterialHeightDisplay(appliance_edge?.material_thickness ?? "")}
      end_text="$/LIN"
      update={price => dispatch(updateShopApplianceEdge({ price: price }, appliance_edge.id))} />
  )
}
