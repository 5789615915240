import { StoneCounterRemovalPrice } from "api/types/price_list/countertop_removal/stone";
import { LaminateCounterRemovalPrice } from "api/types/price_list/countertop_removal/laminate";

export enum ActionType {
  GET_STONE_COUNTERTOP_REMOVAL_PRICE = "GET_STONE_COUNTERTOP_REMOVAL_PRICE",
  UPDATE_STONE_COUNTERTOP_REMOVAL_PRICE = "UPDATE_STONE_COUNTERTOP_REMOVAL_PRICE",
  GET_LAMINATE_COUNTERTOP_REMOVAL_PRICE = "GET_LAMINATE_COUNTERTOP_REMOVAL_PRICE",
  UPDATE_LAMINATE_COUNTERTOP_REMOVAL_PRICE = "UPDATE_LAMINATE_COUNTERTOP_REMOVAL_PRICE"
}

interface IGetStoneCountertopRemovalPrice {
  type: ActionType.GET_STONE_COUNTERTOP_REMOVAL_PRICE
  stone_countertop_removals: StoneCounterRemovalPrice
}

interface IUpdateStoneCountertopRemovalPrice {
  type: ActionType.UPDATE_STONE_COUNTERTOP_REMOVAL_PRICE,
  stone_countertop_removal: StoneCounterRemovalPrice
}

interface IGetLaminateCountertopRemovalPrice {
  type: ActionType.GET_LAMINATE_COUNTERTOP_REMOVAL_PRICE,
  laminate_countertop_removals: LaminateCounterRemovalPrice
}

interface IUpdateLaminateCountertopPrice {
  type: ActionType.UPDATE_LAMINATE_COUNTERTOP_REMOVAL_PRICE,
  laminate_countertop_removal: LaminateCounterRemovalPrice
}

export type Action = IGetStoneCountertopRemovalPrice | IUpdateStoneCountertopRemovalPrice | IGetLaminateCountertopRemovalPrice | IUpdateLaminateCountertopPrice;
